(function () {

    'use strict';

    angular
        .module('app')
        .controller('DatepickerController', DatepickerController);

    function DatepickerController($scope, $timeout) {

        $scope.init = function (valid_dates, pickerid) {
            $scope.valid_dates = valid_dates;
            $scope.pickerid = typeof pickerid == 'undefined' ? 'datepicker' : pickerid;
        };

        $timeout(function () {
            if ($scope.valid_dates === 'all') {
                $('#' + $scope.pickerid).datepicker({
                    showOn: 'button',
                    buttonImage: 'assets/images/datepicker.jpg',
                    buttonText: 'Show Datepicker'
                });
            } else if ($scope.valid_dates === 'norestrictions') {
                $('#' + $scope.pickerid).datepicker({
                    showOn: 'button',
                    buttonImage: 'assets/images/datepicker.jpg',
                    buttonText: 'Show Datepicker',
                    changeMonth: true,
                    changeYear: true,
                    yearRange: '-100:+0',
                    maxDate: 0
                });
            } else if ($scope.valid_dates === 'allfuturedates') {
                $('#' + $scope.pickerid).datepicker({
                    showOn: 'button',
                    buttonImage: 'assets/images/datepicker.jpg',
                    buttonText: 'Show Datepicker',
                    minDate: 0
                });
                
            } else if ($scope.valid_dates === 'restrictTo15Days') { // used when prospect books showing using public link
                $('#' + $scope.pickerid).datepicker({
                    showOn: 'button',
                    buttonImage: 'assets/images/datepicker.jpg',
                    buttonText: 'Show Datepicker',
                    minDate: 0,
                    maxDate: '+15d'
                });
            } else if ($scope.valid_dates === 'allpastdates') {
                $('#' + $scope.pickerid).datepicker({
                    showOn: 'button',
                    buttonImage: 'assets/images/datepicker.jpg',
                    buttonText: 'Show Datepicker',
                    maxDate: 0,
                    // dateFormat: 'yy-mm-dd'
                });
            } else if ($scope.valid_dates === 'afterjuly12023') {
                $('#' + $scope.pickerid).datepicker({
                    showOn: 'button',
                    buttonImage: 'assets/images/datepicker.jpg',
                    buttonText: 'Show Datepicker',
                    minDate: new Date(2023, 6, 1)
                });
            }else {
                $('#' + $scope.pickerid).datepicker({
                    showOn: 'button',
                    buttonImage: 'assets/images/datepicker.jpg',
                    buttonText: 'Show Datepicker',
                    minDate: 0,
                    beforeShowDay: function (date) {
                        if (date.getDate() <= 28) {
                            if ($scope.valid_dates.length !== 0 && $scope.valid_dates.indexOf(date.getDate()) === -1) {
                                return [false, ''];
                            }
                            return [true, ''];
                        }
                        return [false, ''];
                    }
                });
            }
        }, 100);

    }

}());
