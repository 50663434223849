(function () {

    'use strict';

    angular
        .module('app')
        .controller('CompanyEditController', CompanyEditController);

    function CompanyEditController($http, $state, companyService, resolvedCompanyData, $scope, $timeout, langService) {

        var vm = this;
        vm.company = resolvedCompanyData.company.data;
        vm.companyName = resolvedCompanyData.company.data.name;
        vm.companyStatus = (vm.company.deleted_at == null) ? 'Enabled' : 'Disabled';
        vm.companyEnableButton = (vm.company.deleted_at == null) ? 'Disable Company' : 'Re-Enable Company';
        vm.options = [{label: 'Yes', value: true}, {label: 'No', value: false}];
        vm.propertyStyleOption = vm.company.property_style ? vm.options[0] : vm.options[1];
        vm.timezoneSetting = vm.company.timezone_setting ? vm.options[0] : vm.options[1];
        vm.property_perks = vm.company.property_perks ? vm.options[0] : vm.options[1];
        vm.payments_enabled = vm.company.payments_enabled ? vm.options[0] : vm.options[1];
        vm.payments_second_send_enabled = vm.company.payments_second_send_enabled ? vm.options[0] : vm.options[1];
        vm.vendors_enabled = vm.company.vendors_enabled ? vm.options[0] : vm.options[1];
        vm.tenant_edit_manager_schedule = vm.company.tenant_edit_manager_schedule ? vm.options[0] : vm.options[1];
        vm.realtor_docs_enabled = vm.company.realtor_docs_enabled ? vm.options[0] : vm.options[1];
        vm.tenant_login_disabled = vm.company.tenant_login_disabled ? vm.options[0] : vm.options[1];
        vm.mr_smoke_alarm = vm.company.mr_smoke_alarm ? vm.options[0] : vm.options[1];
        vm.apollo_insurance_compliance = vm.company.apollo_insurance_compliance ? vm.options[0] : vm.options[1];
        vm.mr_tenant_info_to_vendor = vm.company.mr_tenant_info_to_vendor ? vm.options[0] : vm.options[1];
        vm.unmask_account_no = vm.company.unmask_account_no ? vm.options[0] : vm.options[1];
        vm.otp_auth_mandatory = vm.company.otp_auth_mandatory ? vm.options[0] : vm.options[1];
        vm.has_angular_messaging_access = vm.company.has_angular_messaging_access ? vm.options[0] : vm.options[1];
        vm.yardi_recurring_charges = vm.company.yardi_recurring_charges ? vm.options[0] : vm.options[1];
        vm.yardi_combine_recurring_charges = vm.company.yardi_combine_recurring_charges ? vm.options[0] : vm.options[1];
        vm.yardi_update_info_on_sync = vm.company.yardi_update_info_on_sync ? vm.options[0] : vm.options[1];
        vm.yardi_sync_instant_decline = vm.company.yardi_sync_instant_decline ? vm.options[0] : vm.options[1];
        vm.rentsync_enabled = vm.company.rentsync_enabled ? vm.options[0] : vm.options[1];
        vm.hubspot_enabled = vm.company.hubspot_enabled ? vm.options[0] : vm.options[1];
        vm.yardi_update_email_on_sync = vm.company.yardi_update_email_on_sync ? vm.options[0] : vm.options[1];
        vm.yardi_sync_evening = vm.company.yardi_sync_evening ? vm.options[0] : vm.options[1];
        vm.yardi_attach_mr = vm.company.yardi_attach_mr ? vm.options[0] : vm.options[1];
        vm.yardi_recurring_unit_charges = vm.company.yardi_recurring_unit_charges ? vm.options[0] : vm.options[1];
        vm.yardi_auto_add_concession = vm.company.yardi_auto_add_concession ? vm.options[0] : vm.options[1];
        vm.yardi_add_hst = vm.company.yardi_add_hst ? vm.options[0] : vm.options[1];
        vm.yardi_disable_pay_other = vm.company.yardi_disable_pay_other ? vm.options[0] : vm.options[1];
        vm.yardi_end_schedule_on_move_out = vm.company.yardi_end_schedule_on_move_out ? vm.options[0] : vm.options[1];
        vm.yardi_lease_require_extension_id = vm.company.yardi_lease_require_extension_id ? vm.options[0] : vm.options[1];
        vm.yardi_link_accounts = vm.company.yardi_link_accounts ? vm.options[0] : vm.options[1];
        vm.yardi_payment_receipt_additional_fields_enabled = vm.company.yardi_payment_receipt_additional_fields_enabled ? vm.options[0] : vm.options[1];
        vm.activeTab = 'welcome_email_message';
        vm.company.welcome_message = vm.company.welcome_message ?? 'Welcome to Payquad and thank you for signing up. We look forward to providing you with great service and simplifying your life.';

        $scope.t = langService.t('companies');

        $scope.rentsync_mappings = vm.company.rentsync_mappings;
        $scope.hubspot_mappings = vm.company.hubspot_mappings;
        $scope.new_rentsync_mapping = {
            building_id: '',
            property_id: ''
        };
        $scope.addRentSyncMapping = function(){
            if ($scope.new_rentsync_mapping.building_id == '' || $scope.new_rentsync_mapping.property_id == '') {
                //console.log('nothing entered');
                //console.log($scope.new_mapping);
                return;
            }
            $http.post('/api/v1/companies/add-rentsync-mapping', $scope.new_rentsync_mapping).success(function(data) {
                if (!data.success) {
                    toastr.error('Error adding mapping');
                    return;
                }

                $scope.rentsync_mappings = data.data;

                $scope.new_rentsync_mapping = {
                    building_id: '',
                    property_id: ''
                };
                toastr.success('Mapping Added');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };
        $scope.removeRentSyncMapping = function(mapping_id){
            if (!confirm('Are you sure you want to delete this mapping?')) {
                return;
            }

            $http.post('/api/v1/companies/delete-rentsync-mapping/' + mapping_id).success(function(data) {
                if (!data.success) {
                    toastr.error('Error deleting mapping');
                    return;
                }

                $scope.rentsync_mappings = data.data;

                toastr.success('Mapping Deleted');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.new_hubspot_mapping = {
            property_code: '',
            property_id: ''
        };
        $scope.addHubspotMapping = function(){
            if ($scope.new_hubspot_mapping.property_code == '' || $scope.new_hubspot_mapping.property_id == '') {
                //console.log('nothing entered');
                //console.log($scope.new_mapping);
                return;
            }
            $http.post('/api/v1/companies/add-hubspot-mapping', $scope.new_hubspot_mapping).success(function(data) {
                if (!data.success) {
                    toastr.error('Error adding mapping');
                    return;
                }

                $scope.hubspot_mappings = data.data;

                $scope.new_hubspot_mapping = {
                    property_code: '',
                    property_id: ''
                };
                toastr.success('Mapping Added');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };
        $scope.removeHubspotMapping = function(mapping_id){
            if (!confirm('Are you sure you want to delete this mapping?')) {
                return;
            }

            $http.post('/api/v1/companies/delete-hubspot-mapping/' + mapping_id).success(function(data) {
                if (!data.success) {
                    toastr.error('Error deleting mapping');
                    return;
                }

                $scope.hubspot_mappings = data.data;

                toastr.success('Mapping Deleted');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        vm.uploadLogoFile = function (file) {
            vm.company.logo_file = file;
        };

        vm.uploadFaviconFile = function (file) {
            vm.company.favicon_file = file;
        };

        vm.uploadNavLogoFile = function (file) {
            vm.company.nav_logo_file = file;
        };

        vm.uploadBackgroundFile = function (file) {
            vm.company.background_file = file;
        };

        vm.uploadCssFile = function (file) {
            vm.company.css_file = file;
        };

        vm.updateCompany = function () {
            vm.company.property_style = vm.propertyStyleOption.value;
            vm.company.property_perks = vm.property_perks.value;
            vm.company.timezone_setting = vm.timezoneSetting.value;
            vm.company.payments_enabled = vm.payments_enabled.value;
            vm.company.payments_second_send_enabled = vm.payments_second_send_enabled.value;
            vm.company.vendors_enabled = vm.vendors_enabled.value;
            vm.company.tenant_edit_manager_schedule = vm.tenant_edit_manager_schedule.value;
            vm.company.realtor_docs_enabled = vm.realtor_docs_enabled.value;
            vm.company.tenant_login_disabled = vm.tenant_login_disabled.value;
            vm.company.mr_smoke_alarm = vm.mr_smoke_alarm.value;
            vm.company.apollo_insurance_compliance = vm.apollo_insurance_compliance.value;
            vm.company.mr_tenant_info_to_vendor = vm.mr_tenant_info_to_vendor.value;
            vm.company.unmask_account_no = vm.unmask_account_no.value;
            vm.company.otp_auth_mandatory = vm.otp_auth_mandatory.value;
            vm.company.has_angular_messaging_access = vm.has_angular_messaging_access.value;
            vm.company.yardi_update_info_on_sync = vm.yardi_update_info_on_sync.value;
            vm.company.yardi_sync_instant_decline = vm.yardi_sync_instant_decline.value;
            vm.company.rentsync_enabled = vm.rentsync_enabled.value;
            vm.company.hubspot_enabled = vm.hubspot_enabled.value;
            vm.company.yardi_update_email_on_sync = vm.yardi_update_email_on_sync.value;
            vm.company.yardi_sync_evening = vm.yardi_sync_evening.value;
            vm.company.yardi_attach_mr = vm.yardi_attach_mr.value;
            vm.company.yardi_recurring_charges = vm.yardi_recurring_charges.value;
            vm.company.yardi_combine_recurring_charges = vm.yardi_combine_recurring_charges.value;
            vm.company.yardi_recurring_unit_charges = vm.yardi_recurring_unit_charges.value;
            vm.company.yardi_auto_add_concession = vm.yardi_auto_add_concession.value;
            vm.company.yardi_add_hst = vm.yardi_add_hst.value;
            vm.company.yardi_disable_pay_other = vm.yardi_disable_pay_other.value;
            vm.company.yardi_end_schedule_on_move_out = vm.yardi_end_schedule_on_move_out.value;
            vm.company.yardi_lease_require_extension_id = vm.yardi_lease_require_extension_id.value;
            vm.company.yardi_link_accounts = vm.yardi_link_accounts.value;
            vm.company.yardi_payment_receipt_additional_fields_enabled = vm.yardi_payment_receipt_additional_fields_enabled.value;
            // vm.company.convirza_group = vm.company.convirza_group;
            companyService.updateCompany(vm.company.id, vm.company);
        };

        vm.deleteCompany = function (id) {
            companyService.deleteCompany(id)
                .success(function (data) {
                    $state.go($state.current, {}, {reload: true});
                    toastr.success('Your company has temporarily been disabled, you can re-enable it at any time', 'Company Disabled Success!');
                })
                .error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }
                });
        };

        vm.enableCompany = function (id) {
            companyService.enableCompany(id)
                .success(function (data) {
                    $state.go($state.current, {}, {reload: true});
                    toastr.success('Your company has been re-enabled', 'Success!');
                })
                .error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }
                });
        };

        vm.editProperty = function (id) {
            $state.go('main.property.edit', {property_id: id});
        };

        vm.editManager = function (id) {
            $state.go('main.managerEdit', {managerId: id});
        };

        vm.editMerchantAccount = function (id) {
            $state.go('main.merchantAccountEdit', {merchantAccountId: id});
        };

        vm.managerPermissionPresets = function (id) {
            console.log(id)
        };

        vm.editVendor = function (id) {
            $state.go('main.vendorEdit', {id: id});
        };

        vm.subdomainPattern = /^[a-zA-Z0-9]*$/;
        vm.phoneNumberPattern = /^\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$/;
        vm.emailPattern = /[-0-9a-zA-Z.+_]+@[-0-9a-zA-Z.+_]+\.[a-zA-Z]{2,4}/;

        vm.extension_types = [
            {value: 'rentmanager', label: 'Rent Manager'},
            {value: 'yardi', label: 'Yardi Voyager'},
            {value: 'yardi_genesis', label: 'Yardi Genesis'},
            {value: 'yardi_genesis_2', label: 'Yardi Genesis II'},
            {value: 'homebuzz', label: 'Homebuzz'}
        ];

        vm.password_reset_reminder_days_list = [
            {value: 0, label: 'Unlimited'},
            {value: 30, label: '30 days'},
            {value: 60, label: '60 days'},
            {value: 90, label: '90 days'},
            {value: 180, label: '180 days'},
            {value: 360, label: '360 days'}
        ];

        vm.extension_display_balance_options = [{value: 0, label: 'Do not display balances',}, {
            value: 1,
            label: 'Display balance total only'
        }, {value: 2, label: 'Allow users to see balance details'}];
        vm.rm_extension_display_balance_options = [{value: 0, label: 'Do not display balances',}, {
            value: 1,
            label: 'Display balance total only'
        }, {value: 2, label: 'Allow users to see balance details'}];
        //vm.rm_extension_display_balance_options = [{value:0,label:'Do not display balances',},{value:1,label:'Display balance'}];

        vm.urlPattern = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
        vm.extension_value_keys = {
            yardi: ['Platform', 'ServerName', 'Database', 'UserName', 'Password'],
            rentmanager: ['Url', 'UserName', 'Password', 'LocationID']
        };

        vm.initPrimaryColourPicker = function () {
            var pickr = Pickr.create({
                el: '.primary-colour-picker',
                theme: 'classic', // or 'monolith', or 'nano'

                default: typeof vm.company.primary_colour != 'undefined' ? vm.company.primary_colour : '#42445a',

                swatches: [
                    'rgba(244, 67, 54, 1)',
                    'rgba(233, 30, 99, 0.95)',
                    'rgba(156, 39, 176, 0.9)',
                    'rgba(103, 58, 183, 0.85)',
                    'rgba(63, 81, 181, 0.8)',
                    'rgba(33, 150, 243, 0.75)',
                    'rgba(3, 169, 244, 0.7)',
                    'rgba(0, 188, 212, 0.7)',
                    'rgba(0, 150, 136, 0.75)',
                    'rgba(76, 175, 80, 0.8)',
                    'rgba(139, 195, 74, 0.85)',
                    'rgba(205, 220, 57, 0.9)',
                    'rgba(255, 235, 59, 0.95)',
                    'rgba(255, 193, 7, 1)'
                ],

                components: {

                    // Main components
                    preview: true,
                    opacity: true,
                    hue: true,

                    // Input / output Options
                    interaction: {
                        hex: true,
                        rgba: true,
                        hsla: true,
                        hsva: true,
                        cmyk: true,
                        input: true,
                        clear: true,
                        save: true
                    }
                }
            });

            pickr.on('save', function (colour, instance) {
                vm.company.primary_colour = colour.toHEXA().toString();
            });
        };

        $timeout(() => {
            $('select.mr-managers').multipleSelect({filter: true, width: 150});
        }, 0);

        vm.addRule = () => {
            vm.company.tickets_escalation_triggers.push({
                maintenance_request_trigger_id: null,
                mr_overdue_managers: []
            });

            $timeout(() => {
                $('select.mr-managers:last').multipleSelect({filter: true, width: 150});
            }, 0);
            console.log(vm.company.tickets_escalation_triggers)
        };

        vm.removeRule = index => {
            vm.company.tickets_escalation_triggers.splice(index, 1);
        };

        $scope.managers = $http.get(`/api/v1/managers`).then(r => vm.managers = r.data.data);

        vm.initPrimaryColourPicker();

        vm.initSecondaryColourPicker = function () {
            var spickr = Pickr.create({
                el: '.secondary-colour-picker',
                theme: 'classic', // or 'monolith', or 'nano'

                default: typeof vm.company.secondary_colour != 'undefined' ? vm.company.secondary_colour : '#42445a',

                swatches: [
                    'rgba(244, 67, 54, 1)',
                    'rgba(233, 30, 99, 0.95)',
                    'rgba(156, 39, 176, 0.9)',
                    'rgba(103, 58, 183, 0.85)',
                    'rgba(63, 81, 181, 0.8)',
                    'rgba(33, 150, 243, 0.75)',
                    'rgba(3, 169, 244, 0.7)',
                    'rgba(0, 188, 212, 0.7)',
                    'rgba(0, 150, 136, 0.75)',
                    'rgba(76, 175, 80, 0.8)',
                    'rgba(139, 195, 74, 0.85)',
                    'rgba(205, 220, 57, 0.9)',
                    'rgba(255, 235, 59, 0.95)',
                    'rgba(255, 193, 7, 1)'
                ],

                components: {
                    // Main components
                    preview: true,
                    opacity: true,
                    hue: true,
                    // Input / output Options
                    interaction: {
                        hex: true,
                        rgba: true,
                        hsla: true,
                        hsva: true,
                        cmyk: true,
                        input: true,
                        clear: true,
                        save: true
                    }
                }
            });

            spickr.on('save', function (colour, instance) {
                vm.company.secondary_colour = colour.toHEXA().toString();
            });
        };
        vm.initSecondaryColourPicker();

        vm.editPortfolio = id => {
            $state.go('main.portfolioCreate', {
                companyId: vm.company.id,
                portfolioId: id
            });
        };

        $scope.messagesCategories = [];
        $http.get('/api/v1/companies/' + vm.company.id + '/message-categories').success(function (data) {
            if (!data) {
                toastr.error('Error retrieving Messages Categories');
                return;
            }
            $scope.messagesCategories = data.data;
        });

        if(vm.company.extension_type){
            $http.get('/api/v1/translations/get-translations/' + vm.company.id).success(function(data) {
                if (!data.success) {
                    return;
                }
                $scope.translations = data.data;
            });
        }

        $scope.updateTranslations = () => {
            $http.post('/api/v1/translations/' + vm.company.id + '/maintenance_request_priorities', {fr: $scope.translations.maintenance_request_priorities.fr}).success(function (data) {
                if (data.code != 200 && data.message) {
                    toastr.error(data.message);
                }
            });
            $http.post('/api/v1/translations/' + vm.company.id + '/maintenance_request_categories', {fr: $scope.translations.maintenance_request_subjects.fr}).success(function (data) {
                if (data.code != 200 && data.message) {
                    toastr.error(data.message);
                }
            });
            toastr.success('Translations Submitted.');
        };

        $scope.addMessageCategory = (name, description) => {
            $http.post('/api/v1/companies/' + vm.company.id + '/message-categories', {name: name, description}).success(function (data) {
                if (data.code != 200 && data.message) {
                    toastr.error(data.message);
                    return;
                }
                if (!data) {
                    toastr.error('Error Creating Category');
                    return;
                }
                $scope.newMessageCategory = '';
                toastr.success('New Category Created.');
                $scope.messagesCategories.push(data.data);
            });
        }

        $scope.ticketCategoryToEdit = {};
        $scope.showEditCategory = category => {
            $scope.ticketCategoryToEdit = {...category};
            $('#editTicketCategory').appendTo('body').modal({backdrop: 'static', keyboard: false});
        }

        $scope.updatingTicketCategory = false;
        $scope.updateTicketCategory = category => {
            $scope.updatingTicketCategory = true;
            $http.put('/api/v1/companies/' + vm.company.id + '/message-categories/' + category.id, category).success(function (data) {
                if (!data) {
                    $scope.updatingTicketCategory = false;
                    toastr.error('Error Updating Category');
                    return;
                }

                $http.get('/api/v1/companies/' + vm.company.id + '/message-categories').success(function (data) {
                    if (!data) {
                        toastr.error('Error retrieving Categories');
                        $scope.updatingTicketCategory = false;
                        return;
                    }
                    $scope.messagesCategories = data.data;
                });
                $scope.updatingTicketCategory = false;
                toastr.success('Category ' + category.name + ' has been updated');

                $('#editTicketCategory').modal('hide');
            }).error(() => $scope.updatingTicketCategory = false);
        }

        $scope.deleteCategory = function (category) {
            if (!confirm('Are you sure you want to delete category?')) {
                return;
            }

            $http.delete('/api/v1/companies/' + vm.company.id + '/message-categories/' + category.id).success(function (data) {
                if (!data) {
                    toastr.error('Error Deleting Category');
                    return;
                }

                let removeIndex = $scope.messagesCategories.map(item => item.id)
                    .indexOf(category.id);

                ~removeIndex && $scope.messagesCategories.splice(removeIndex, 1);

                toastr.success('Category ' + category.name + ' has been removed');
            });
        };

        $scope.messagesTags = [];
        $http.get('/api/v1/companies/' + vm.company.id + '/ticket-tags').success(function (data) {
            if (!data) {
                toastr.error('Error retrieving Tags');
                return;
            }
            $scope.messagesTags = data.data;
        });

        $scope.deleteTag = function (tag) {
            if (!confirm('Are you sure you want to delete the tag?')) {
                return;
            }

            $http.delete('/api/v1/companies/' + vm.company.id + '/ticket-tags/' + tag.id).success(function (data) {
                if (!data) {
                    toastr.error('Error Deleting Tag');
                    return;
                }

                let removeIndex = $scope.messagesTags.map(item => item.id)
                    .indexOf(tag.id);

                ~removeIndex && $scope.messagesTags.splice(removeIndex, 1);

                toastr.success('Tag ' + tag.name + ' has been removed');
            });
        };

        $scope.addMessageTag = (name) => {
            $http.post('/api/v1/companies/' + vm.company.id + '/ticket-tags', {name: name}).success(function (data) {
                if (data.code != 200 && data.message) {
                    toastr.error(data.message);
                    return;
                }
                if (!data) {
                    toastr.error('Error Creating Tag');
                    return;
                }
                $scope.newMessageTag = '';
                toastr.success('New Tag Created.');
                $scope.messagesTags.push(data.data);
            });
        }

    }

}());
