(function () {
    'use strict';

    angular
        .module('app')
        .directive('pqProspectShowingForm', pqProspectShowingForm);

    function pqProspectShowingForm() {
        return {
            restrict: 'E',
            controller: 'PqProspectShowingFormController',
            templateUrl: 'scripts/modules/prospect/prospect-showing-form/prospect-showing-form.html',
            scope: {
                prospect: '=',
                step: '=',
                showings: '='
            }
        };
    }

    angular
        .module('app')
        .controller('PqProspectShowingFormController', function ($scope, $rootScope, $http, showingService,langService) {
            $scope.t = langService.t('lease_app');
            $scope.agents = [];
            $scope.ref = {agent: {}};
            $scope.property_units = [];
            $scope.provinces = [];
            $scope.countries = [];
            $scope.managers = [];
            $scope.companies = [];
            $scope.properties = [];
            $scope.show_floorplans = 0;
            $scope.floorplans = [];
            $scope.isTimeSelectionActive = [];
            $scope.virtualMethods = [$scope.t('in_person'), 'WhatsApp', 'Zoom', 'FaceTime', 'Duo', 'Skype', $scope.t('other')];

            if ($scope.showings) {
                $scope.showings.forEach(function () {
                    $scope.isTimeSelectionActive.push({ isActive: false });
                })
            }

            $scope.statuses = [];

            $scope.addShowing = () => {
                $scope.showings.push({
                    start_date: '',
                    start_time: '',
                    virtual_showing_type: '',
                    other_virtual_type: ''
                });
                $scope.prospect.showings = $scope.showings;
                $scope.isTimeSelectionActive.push({isActive: false});
                setTimeout(function () {
                    const index = $scope.showings.length - 1;
                    $('#showing-date-' + index).datepicker({
                        minDate: 0
                    });
                }, 0);
            }

            $scope.deleteShowing = () => {
                $scope.showings.splice($scope.showings.length - 1, 1);
                $scope.prospect.showings = $scope.showings;
                $scope.isTimeSelectionActive.splice($scope.isTimeSelectionActive.length - 1, 1);
            }

            $scope.isVirtualTour = () => {
                if (!$scope.properties.length) {
                    return false;
                } else {
                    const property = $scope.properties.find(item => +item.id === +$scope.prospect.property_id);
                    return property ? property.virtual_showings_enabled : null;
                }
            };

            $scope.showNoEmail = () => {
                if (!$scope.properties.length) {
                    return false;
                } else {
                    const property = $scope.properties.find(item => +item.id === +$scope.prospect.property_id);
                    return property ? property.hide_lease_no_email == 0 : false;
                }
            };

            $scope.default_referral_locations = [
                'Google',
                'Newspaper Ad',
                'Online Ad',
                'Zumper/Padmapper',
                'Kijiji',
                'Rentboard.ca',
                'Rentseeker.ca',
                '4 Rent',
                'Apartments.com',
                'Facebook',
                'Gotta Rent',
                'Listnet',
                'Louer',
                'Prop2Go',
                'Rent Board USA',
                'Rent Canada',
                'Rent Compass',
                'Rent Faster',
                'Rentals For Newcomers',
                'Rew',
                'Spacelist',
                'Toronto Rentals',
                'Trovit',
                'View It',
                'Walkscore',
                'Zillow'
            ];
            $scope.referral_locations = $scope.default_referral_locations;

            $scope.showingTime = [];

            $scope.resetTime = (index = null) => {
                if ($scope.showings && index) {
                    $scope.isTimeSelectionActive[index].isActive = false;
                    $scope.showings[index].start_time = '';
                } else if ($scope.showings) {
                    $scope.isTimeSelectionActive.forEach(item => {
                        item.isActive = false;
                    });
                    $scope.showings.forEach(item => {
                        item.start_time = '';
                    });
                }
            };

            $scope.getAgents = function () {
                $http.post('/api/v1/prospect/get-agents/' + $scope.prospect.property_id).success(function (data) {
                    if (!data.success) {
                        return;
                    }

                    $scope.agents = data.data.agents;

                    if ($scope.agents.length > 0) {
                        $scope.agents.unshift({
                            first_name: $scope.t('Select Agent'),
                            last_name: ''
                        });
                        if ($scope.prospect.agent_first_name == '' || $scope.prospect.agent_first_name == null) {
                            $scope.ref.agent = $scope.agents[0];
                        } else {
                            angular.forEach($scope.agents, function (agent, key) {
                                if ($scope.prospect.agent_first_name == agent.first_name && $scope.prospect.agent_last_name == agent.last_name) {
                                    $scope.ref.agent = agent;
                                }
                            });
                        }
                    } else {
                        $scope.ref.agent = {
                            first_name: '',
                            last_name: ''
                        };
                    }

                    //if ($scope.prospect.agent_first_name == '' || $scope.prospect.agent_first_name == null) {
                    $scope.prospect.agent_first_name = $scope.ref.agent.first_name;
                    $scope.prospect.agent_last_name = $scope.ref.agent.last_name;
                    //}
                }).error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }
                });
            };

            $scope.getReferralLocations = function () {
                $http.post('/api/v1/lease-application/get-referral-locations/' + $scope.prospect.property_id).success(function (data) {
                    if (!data.success) {
                        return;
                    }

                    if (data.data.referral_locations.length > 0) {
                        $scope.referral_locations = data.data.referral_locations;
                    } else {
                        $scope.referral_locations = $scope.default_referral_locations;
                    }
                    var first_referral = $scope.prospect.referral;
                    $scope.prospect.referral = '';
                    setTimeout(function () {
                        angular.forEach($scope.referral_locations, function (source, key) {
                            if (first_referral == source) {
                                $scope.prospect.referral = $scope.referral_locations[key];
                            }
                        });
                    }, 50);
                }).error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }
                });
            };

            $scope.setAgentName = () => {
                $scope.prospect.agent_first_name = $scope.ref.agent.first_name;
                $scope.prospect.agent_last_name = $scope.ref.agent.last_name;
            };

            $scope.onDateClick = (index) => {
                $('#showing-date-' + index).focus();
            }

            $scope.isShowingsEnabled = () => {
                if ($scope.prospect.property) {
                    return $scope.prospect.property.showings_enabled;
                }
            }

            $scope.getTime = (index) => {
                if (!$scope.isTimeSelectionActive[index].isActive) {
                    if (!$scope.prospect.property_id || !$scope.showings[index].start_date) {
                        toastr.error($scope.t('Please specify property and date first'));
                    } else {
                        const date = moment($scope.showings[index].start_date).format('YYYY-MM-DD');
                        showingService.checkTimes($scope.prospect.property_id, date, $scope.prospect.manager_id).then(data => {
                            $scope.showingTime[index] = data;
                            if ($scope.showingTime[index].length) {
                                $scope.isTimeSelectionActive[index].isActive = true;
                            } else {
                                toastr.error($scope.t('Sorry, there are no available times for this date'));
                            }
                        });
                    }
                } else {
                    $scope.isTimeSelectionActive[index].isActive = false;
                }
            };

            $scope.setTime = (index, time) => {
                $scope.showings[index].start_time = time;
                $scope.isTimeSelectionActive[index].isActive = false;
            };

            $scope.getCompanies = function () {
                $http.post('/api/v1/prospect/get-companies').success(function (data) {
                    if (!data.success) {
                        return;
                    }

                    $scope.companies = data.data.companies;
                    if ($scope.prospect.id === 0 || $scope.prospect.id === '') {
                        $scope.prospect.company_id = $scope.companies[0].id;
                    }
                    $scope.getManagers();
                    $scope.getProperties();
                    $scope.getPropertyUnits();
                });
            };

            $scope.getProvinces = function () {
                $http.post('/api/v1/countrystate/get-provinces/' + $scope.prospect.country).success(function (data) {
                    if (!data.success) {
                        return;
                    }

                    $scope.provinces = data.data.provinces;
                });
            };

            $scope.getCountries = function () {
                $http.post('/api/v1/countrystate/get-countries').success(function (data) {
                    if (!data.success) {
                        return;
                    }

                    $scope.countries = data.data.countries;
                    $scope.prospect.country = 'CA';
                    $scope.getProvinces();
                });
            };

            $scope.companyChanged = function () {
                $scope.getManagers();
                $scope.getProperties();
            };

            $scope.getManagers = function () {
                $http.post('/api/v1/prospect/get-managers/' + $scope.prospect.company_id).success(function (data) {
                    if (!data.success) {
                        return;
                    }

                    $scope.managers = data.data.managers;
                });
            };

            $scope.getStagesList = function () {
                $http.get('/api/v1/prospect/get-stage-list').success((data) => {
                    $scope.statuses = data.data;
                    $scope.prospect.stage = $scope.prospect.stage ?? $scope.statuses[0].value;
                })      
            }

            $scope.showingStatuses = [
                {value: 'Booked', label: $scope.t('Booked')},
                {value: 'Completed', label: $scope.t('Completed')},
                {value: 'No show', label: $scope.t('No show')},
                {value: 'Cancelled', label: $scope.t('Cancelled')},
            ];

            $scope.getProperties = function () {
                $http.post('/api/v1/prospect/get-properties/' + $scope.prospect.company_id).success(function (data) {
                    if (!data.success) {
                        return;
                    }

                    $scope.properties = data.data.properties;
                    $scope.getFloorplans();
                });
            };

            $scope.getPropertyUnits = function (type) {
                $scope.getAgents();
                $scope.getFloorplans();
                $scope.getReferralLocations();
                const property_id = type === 'invite' ? $scope.invite.property_id : $scope.prospect.property_id;
                $http.post('/api/v1/properties/get-property-units-for-manager-payments/' + property_id).success(function (data) {
                    if (!data.success) {
                        return;
                    }

                    $scope.property_units = [];
                    angular.forEach(data.data, function (unit, key) {
                        if (!$scope.property_units.includes(unit.unit)) {
                            $scope.property_units.push(unit.unit);
                        }
                    });
                }).error(function (data) {
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }
                });
            };

            $scope.initDatePickers = function () {
                setTimeout(function () {
                    setTimeout(function () {
                        if ($scope.prospect.contact_at == 'Invalid date') {
                            $scope.prospect.contact_at = '';
                        }
                    }, 500);

                    $('#contact-at').datepicker();
                    $('#move-in-at').datepicker();
                    if ($scope.showings) {
                        $scope.showings.forEach(function (value, index) {
                            $('#showing-date-' + index).datepicker({
                                minDate: 0
                            });
                        })
                    }
                }, 0);
            };

            $scope.getFloorplans = function(){
                const property = $scope.properties.find(item => +item.id === +$scope.prospect.property_id);
                if (typeof property == 'undefined' || property.company.extension_type != 'yardi' || property.lease_applications_extension_enabled != 1 || property.lease_application_yardi_unit_types ==0) {
                    return;
                }
                $http.post('/api/v1/lease-application/get-floorplans/' + $scope.prospect.property_id).success(function(data) {
                    if (!data.success) {
                        return;
                    }

                    $scope.floorplans = data.data.floorplans;
                    $scope.show_floorplans = 1;

                    // loop over floorplans
                    angular.forEach($scope.floorplans, function(floorplan, key) {
                        if ($scope.prospect.unit_type == floorplan) {
                            $scope.prospect.floorplan = key;
                        }
                    });
                }).error(function(data){
                    if (typeof data.stopaction != 'undefined') {
                        return;
                    }

                    toastr.error(data.error.message);
                    $state.go('main.leaseApplicationCreateCompanies');
                });
            };

            $scope.prospect.no_email = 0;
            $scope.getRegistrationCode = () => {
                $http.post('/api/v1/prospect/get-unique-registration-code').then(function success(data) {
                    if (!data,data.success) {
                        return;
                    }
                    $scope.prospect.registration_code = data.data.data.registration_code;
                }, function error(data){
                    if (typeof data.data.stopaction != 'undefined') {
                        return;
                    }
                });
            }

            if ($rootScope.authUser.permissions === 'payquad_admin') {
                $scope.getCompanies();
            } else {
                $scope.getManagers();
                $scope.getProperties();
                $scope.getPropertyUnits();
            }
            $scope.getCountries();
            $scope.initDatePickers();
            $scope.getStagesList();

            if($scope.prospect.email.indexOf('@payquad.com') > 0){
                $scope.prospect.registration_code = $scope.prospect.email.split("@")[0];
                $scope.prospect.no_email = 1;
            } else {
                $scope.getRegistrationCode();
            }
        });
})();
