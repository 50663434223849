import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import {Capacitor} from "@capacitor/core";

@Injectable({
  providedIn: "root",
})
export class IconRegistryService {
  private iconRegistry: MatIconRegistry;
  private sanitizer: DomSanitizer;
  private pathPrefix: string = 'dist/';

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    this.iconRegistry = iconRegistry;
    this.sanitizer = sanitizer;
    this.pathPrefix = Capacitor.isNativePlatform() ? '' : 'dist/';
  }

  registerIcons() {
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "access",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/menu/access.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "box",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/menu/box.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "change",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/menu/change.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "copy",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/menu/copy.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "docs",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/menu/docs.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "edit",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/menu/edit.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "headphones",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/menu/headphones.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "home",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/menu/home.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "house-star",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/menu/house-star.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "settings",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/menu/settings.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "user",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/menu/user.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "wallet",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/menu/wallet.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "wrench",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/menu/wrench.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "arrow-forward",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Arrows, Diagrams/Arrow, Forward.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "bell-notifications",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Interface, Essential/bell-notifications.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "invoice-accounting-document",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Payments, Finance/invoice-accounting-document.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "discount",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Shopping, Ecommerce/Sale, Discount, Promotion.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "email_event",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/ProspectEvents/email.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "email_event_incoming",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/ProspectEvents/email-incoming.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "email_event_incoming_new_lead",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/ProspectEvents/email-incoming-new-lead.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "email_event_outgoing",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/ProspectEvents/email-outgoing.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "phone_event",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/ProspectEvents/phone.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "phone_event_incoming",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/ProspectEvents/phone-incoming.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "phone_event_missed",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/ProspectEvents/phone-missed.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "phone_event_outgoing",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/ProspectEvents/phone-outgoing.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "play",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/ProspectEvents/play.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "pause",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/ProspectEvents/pause.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "prospect_event",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/ProspectEvents/prospect.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "showing_event",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/ProspectEvents/showing.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "users-profile-group",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/User/users-profile-group.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "star-checkmark",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/star-checkmark.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "login-logout-arrow",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/login-logout-arrow.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "arrow-up",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Arrows, Diagrams/Trailing Icon.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "menu-burger",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Interface, Essential/menu-burger.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "open",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Desktop Navigation/Open.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "chevron-down",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Arrows, Diagrams/Arrow chevron down.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "dots",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Desktop Navigation/dots.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "check-double",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Arrows, Diagrams/check-double.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "refresh-cw",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Arrows, Diagrams/refresh-cw.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "info",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Desktop Navigation/info.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "modern-building-4",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Desktop Navigation/modern-building-4.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "modern-building-4-thin",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Desktop Navigation/modern-building-4-thin.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "language",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Interface, Essential/world.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "checkmark_circle",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Interface, Essential/checkmark-circle.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "mail",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Desktop Navigation/email-mail-letter-checkmark.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "printer",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Interface, Essential/Printer.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "calendar",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Calendar.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "bar-chart",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/stats.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "ellipsis",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/ellipsis.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "user-circle",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/user-circle.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "offline",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/offline.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "offline_pref",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/offline_pref.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "image-placeholder",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/ProspectEvents/image-placeholder.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "phone",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/phone.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "phone-filled",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/phone-filled.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "calling-filled",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/calling-filled.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "email",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/email.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "email-filled",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/email-filled.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "trash",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Interface, Essential/trash.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "edit",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Interface, Essential/edit.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "note",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Interface, Essential/note.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "orange-calendar",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/ProspectEvents/orange-calendar.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "3-user",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/ProspectEvents/orange-3-user.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "notification-bell",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/ProspectEvents/orange-notification-bell.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "orange-house",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/ProspectEvents/orange-house.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "3-user",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/ProspectEvents/orange-3-User.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "pe-call",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/ProspectEvents/pe-call.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "pe-mail",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/ProspectEvents/pe-mail.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "paper-clip",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/paper-clip.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
        "pq",
        "attachment-paperclip",
        this.sanitizer.bypassSecurityTrustResourceUrl(
            "dist/assets/icons/attachment-paperclip.svg"
        )
    )
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "send",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/Interface, Essential/send.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "reply",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/reply-outline.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "no-messages",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/no-message.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
      'pq',
      'mailbox_inbox_archive',
      this.sanitizer.bypassSecurityTrustResourceUrl(this.pathPrefix + 'assets/icons/mailbox-inbox-archive.svg')
    );
    this.iconRegistry.addSvgIconInNamespace(
      'pq',
      'send_message',
      this.sanitizer.bypassSecurityTrustResourceUrl(this.pathPrefix + 'assets/icons/Send message, Send, Share.svg')
    );
    this.iconRegistry.addSvgIconInNamespace(
      'pq',
      'folder_archive',
      this.sanitizer.bypassSecurityTrustResourceUrl(this.pathPrefix + 'assets/icons/older-archive.svg')
    );
    this.iconRegistry.addSvgIconInNamespace(
      "pq",
      "wand",
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.pathPrefix + "assets/icons/wand.svg"
      )
    );
    this.iconRegistry.addSvgIconInNamespace(
        'pq',
        'arrow_left',
        this.sanitizer.bypassSecurityTrustResourceUrl(
            this.pathPrefix + 'assets/icons/arrow-left.svg'
        )
    );
    // this.iconRegistry.addSvgIconInNamespace(
    //     'pq',
    //     'messaging_folder_archive',
    //     this.sanitizer.bypassSecurityTrustResourceUrl(
    //         'dist/assets/icons/folder_archive.svg'
    //     )
    // );  TODO: remove this icon if not used
    this.iconRegistry.addSvgIconInNamespace(
        'pq',
        'pdf',
        this.sanitizer.bypassSecurityTrustResourceUrl(
            'dist/assets/icons/Documents/pdf.svg'
        )
    );
    this.iconRegistry.addSvgIconInNamespace(
        'pq',
        'doc',
        this.sanitizer.bypassSecurityTrustResourceUrl(
            'dist/assets/icons/Documents/doc.svg'
        )
    );
  }
}
