import {Component, Input, OnInit} from '@angular/core';
import {UnsubscribableComponent} from '../../shared/classes/abstract/unsubscribable-component';
import {User} from '../../shared/interfaces/user';
import {LangService} from '../../shared/services/lang.service';
import {logout} from '../../store/app.actions';
import {Store} from '@ngrx/store';
import {State} from '../../store/app.state';
import {Capacitor} from '@capacitor/core';
import {Preferences} from "@capacitor/preferences";

@Component({
  selector: 'app-sidenav-profile',
  templateUrl: './sidenav-profile.component.html',
  styleUrls: ['./sidenav-profile.component.scss']
})
export class SidenavProfileComponent extends UnsubscribableComponent implements OnInit {
  @Input()
  public authUser: User;
  isApp = Capacitor.isNativePlatform();

  public t: (key: string) => string;

  constructor(
    private langService: LangService,
    private store: Store<State>,
  ) {
    super();
    this.t = langService.t('navbar');
  }

  ngOnInit(): void {
  }

  public async signOut(): Promise<void> {
    if (Capacitor.isNativePlatform() ) {
      await Preferences.clear();
    }
    this.store.dispatch(logout());
  }

}
