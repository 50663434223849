import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AppService } from "../app.service";
import {
  changeUser,
  loadBranding,
  loadTranslations,
  loadUser,
  logout,
  setBranding,
  setLocale,
  setTranslations,
  setUser,
  httpError,
  sessionExpired,
} from "./app.actions";
import { setPropertyOptions } from "../modules/dashboard/store/dashboard.actions";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map, mergeMap, take, tap } from "rxjs/operators";
import {from, of} from "rxjs";
import { ANGULAR_JS_EVENTS, APP_USER_STATUS } from "../shared/constants";
import { AngularJsService } from "../shared/services/angular-js.service";
import { JWTService } from "../shared/services/JWT.service";
import { Router } from "@angular/router";
import { State } from "src/app/store/app.state";
import { TranslateService } from "@ngx-translate/core";
import { CustomisationService } from "../shared/services/customisation.service";
import {Network} from "@capacitor/network";

@Injectable()
export class AppEffects {
  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUser),
      exhaustMap(() => this.appService.loadUser()),
      mergeMap((response) => [
        setUser({
          user: response.success
            ? response.data
            : APP_USER_STATUS.NOT_AUTHORIZED,
        }),
        loadBranding(),
      ])
    )
  );

  changeUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeUser),
      exhaustMap((action) => this.appService.changeUser(action.id)),
      mergeMap((response) => [
        setUser({
          user: response.success
            ? response.data
            : APP_USER_STATUS.NOT_AUTHORIZED,
        }),
        loadTranslations({ locale: response.data.locale }),
        loadBranding(),
      ])
    )
  );

  loadBranding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBranding),
      exhaustMap(() => this.appService.loadBranding()),
      map((response) => setBranding({ branding: response.data }))
    )
  );

  setBranding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setBranding),
      tap(() => {
        return this.customisationService.config.pipe(
          take(1)
        ).subscribe()
      }),
    ), { dispatch: false }
  );


  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      exhaustMap(() => from(Network.getStatus()).pipe(
        exhaustMap(status => {
          if (!status.connected) {
            this.store.dispatch(setUser({ user: null }));
            this.angularJsService.fireEvent(ANGULAR_JS_EVENTS.SIGN_OUT);
            this.JWTService.removeToken();
            this.store.dispatch(setPropertyOptions({ properties: [] }));
            this.router.navigateByUrl('/n/login');
            return of();
          }
          return this.appService.signOut().pipe(
            map(() => setUser({ user: null })),
            tap(() => {
              console.log('signing out');
              this.angularJsService.fireEvent(ANGULAR_JS_EVENTS.SIGN_OUT);
              this.JWTService.removeToken();
              this.store.dispatch(setPropertyOptions({ properties: [] }));
              this.router.navigateByUrl('/n/login');
            }),
            catchError((error) => {
              return of(httpError({ payload: { message: 'Logout Failed' } }));
            })
          );
        })
      ))
    )
  );

  sessionExpired$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sessionExpired),
      map(() => setUser({ user: null })),
      tap(() => this.JWTService.removeToken())
    )
  );

  loadTranslations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTranslations),
      exhaustMap((action) =>
        this.appService.loadTranslations(action.locale).pipe(
          map((response) =>
            setTranslations({ translations: response.translations })
          ),
          catchError((error) => {
            if (!Boolean(window.cordova)) {
              return of(
                httpError({ payload: { message: "Get Translations Failed" } })
              );
            }
          })
        )
      )
    )
  );

  setLocale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setLocale),
      tap((action) => {
        this.translate.use(action.locale);
      })
    ), { dispatch: false }
  );

  handleErrorMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(httpError),
      tap(({ payload }) => {
        alert(payload.message);
      })
    ), { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private appService: AppService,
    private angularJsService: AngularJsService,
    private JWTService: JWTService,
    private router: Router,
    private store: Store<State>,
    private translate: TranslateService,
    private customisationService: CustomisationService,
  ) { }
}
