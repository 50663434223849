(function() {

    'use strict';

    angular
    .module('app')
    .controller('MaintenanceRequestPoEditController', maintenanceRequestPoEditController);

    function maintenanceRequestPoEditController($scope,$http,$state,$stateParams,$rootScope,$interval,$timeout) {
        $scope.po = {
            id: $stateParams.id,
            maintenance_request_id: 0,
            number: '',
            memo: '',
            po_at: moment().format('MM/DD/YYYY')
        };
        $scope.selectedMobileFile = null;
        $scope.selectedMobileFileName = null;
        $scope.isNative = false;

        $timeout(function(){
            $scope.isNative = window.Capacitor.isNativePlatform()
        }, 1000);

        $scope.updatePo = function() {
            $scope.savingPo = true;
            var data = new FormData();
            if (!$scope.isNative && document.getElementById('file1').files.length > 0) {
                data.append('document', document.getElementById('file1').files[0]);
            }
            if ($scope.isNative && $scope.selectedMobileFile) {
                data.append('document', $scope.selectedMobileFile);
                data.append('document_name', $scope.selectedMobileFileName);
            }

            $.each($scope.po, function (index, value) {
                if (index === 'invoices') {
                    $.each(value, (i, v) => {
                        $.each(v.line_items, (i, v) => {
                            v.order = i+1;
                        })
                    })
                    data.append('invoices', angular.toJson(value));
                    return;
                }
                data.append(index, value);
            });

            const headers = window.Capacitor.isNativePlatform()
                ? {
                    'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA',
                }
                : {
                    'Content-Type': undefined,
                };

            $http.post('/api/v1/maintenance-request-po/update', data, {transformRequest: angular.identity, headers: headers}).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error updating PO");
                    return;
                }

                $scope.savingPo = false;
                $state.go('main.maintenanceRequestEdit', {id: $scope.po.maintenance_request_id});
                toastr.success('PO updated');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.savingPo = false;
                toastr.error(data.error.message);
            });
        }

        $scope.deletePo = function() {
            if (!confirm('Are you sure you want to delete this po?')) {
                return; // they did not confirm
            }
            $scope.savingPo = true;

            $http.post('/api/v1/maintenance-request-po/delete/' + $scope.po.id).success(function(data) {
                if (!data.success) {
                    toastr.error(data.errors,"Error deleting PO");
                    return;
                }

                $state.go('main.maintenanceRequestEdit', {id: $scope.po.maintenance_request_id});
                toastr.success('PO deleted');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $scope.savingPo = false;
                toastr.error(data.error.message);
            });
        }

        $scope.available_vendors = [];
        $scope.vendor = 5;

        $scope.loadingVendors = false;

        $scope.getPo = function(){
            $scope.loadingVendors = true;
            $http.post('/api/v1/maintenance-request-po/get/' + $scope.po.id).success(function(data) {
                if (!data.success) {
                    $state.go('main.maintenanceRequestEdit', {id: $scope.po.maintenance_request_id});
                }

                // lets populate stuff
                var po = data.data.po;

                $scope.po.number = po.number;
                $scope.po.memo = po.memo;
                $scope.po.po_at = po.po_at_formatted;
                $scope.po.maintenance_request_id = po.maintenance_request_id;
                $scope.po.vendor_id = po.vendor_id;
                $scope.po.invoices = po.invoices;
                $scope.po.attachment_url = po.attachment_url;

                $http.get('/api/v1/maintenance-requests/edit/' + $scope.po.maintenance_request_id).success(function (mrResponse) {
                    $scope.available_vendors = mrResponse.data.available_vendors;
                    $scope.loadingVendors = false;
                });

            });
        }

        $scope.initDatePickers = function(){
            setTimeout(function(){
                $('#po-at').datepicker();
            }, 0);
        }
        $scope.getPo();
        $scope.initDatePickers();

        if ($rootScope.authUser.permissions == 'tenant' || $rootScope.authUser.permissions == 'junior_manager') {
            $state.go('main.maintenanceRequestEdit', {id: $scope.po.maintenance_request_id});
            return 1;
        }

        $scope.addLineItem = function () {
            $scope.newInvoice.line_items.push({
                label: '',
                sub_label: '',
                order: 1,
                type: 'line',
                tax_type: 'percentage',
                amount: ''
            });
        };

        $scope.addTaxItem = function () {
            $scope.newInvoice.tax_items.push({
                label: '',
                sub_label: '',
                order: 1,
                type: 'tax',
                tax_type: 'percentage',
                amount: ''
            });
        };

        $scope.removeLineItem = function (key) {
            $scope.newInvoice.line_items.splice(key, 1);
        };

        $scope.removeTaxItem = function (key) {
            $scope.newInvoice.tax_items.splice(key, 1);
        };

        $scope.moveLineItemDown = function (key) {
            var newkey = key + 1;

            if (typeof $scope.newInvoice.line_items[newkey] == 'undefined') {
                return;
            }

            var temp = $scope.newInvoice.line_items[newkey];
            $scope.newInvoice.line_items[newkey] = $scope.newInvoice.line_items[key];
            $scope.newInvoice.line_items[key] = temp;
        };

        $scope.moveLineItemUp = function (key) {
            if (key == 0) {
                return;
            }

            var newkey = key - 1;

            var temp = $scope.newInvoice.line_items[newkey];
            $scope.newInvoice.line_items[newkey] = $scope.newInvoice.line_items[key];
            $scope.newInvoice.line_items[key] = temp;
        };

        $scope.moveTaxItemDown = function (key) {
            var newkey = key + 1;

            if (typeof $scope.newInvoice.tax_items[newkey] == 'undefined') {
                return;
            }

            var temp = $scope.newInvoice.tax_items[newkey];
            $scope.newInvoice.tax_items[newkey] = $scope.newInvoice.tax_items[key];
            $scope.newInvoice.tax_items[key] = temp;
        };

        $scope.moveTaxItemUp = function (key) {
            if (key == 0) {
                return;
            }

            var newkey = key - 1;

            var temp = $scope.newInvoice.tax_items[newkey];
            $scope.newInvoice.tax_items[newkey] = $scope.newInvoice.tax_items[key];
            $scope.newInvoice.tax_items[key] = temp;
        }

        $scope.editInvoice = invoice => {
            $scope.newInvoice = invoice;
            $('#editPOInvoice').appendTo('body').modal({backdrop: 'static', keyboard: false});


        }

        $scope.deleteInvoice = invoice => {
            if (invoice.uniqId){
                $scope.po.invoices = $scope.po.invoices.filter(item => {
                    return item.id || item.uniqId !== invoice.uniqId;
                })
            } else {
                $scope.po.invoices = $scope.po.invoices.filter(item => {
                    return item.id !== invoice.id;
                })
            }
        }

        $scope.showCreateInvoicePopUp = () => {
            $scope.newInvoice = {
                uniqId: '',
                name: '',
                number: '',
                line_items: [],
                tax_items: []
            }
            $('#editPOInvoice').appendTo('body').modal({backdrop: 'static', keyboard: false});
        }

        $scope.saveNewInvoice = () => {
            if ($scope.newInvoice.uniqId.length === 0) {
                $scope.newInvoice.uniqId = '_' + Math.random().toString(36).substr(2, 9);
                $scope.po.invoices.push($scope.newInvoice);
            }
            $scope.newInvoice = {
                uniqId: '',
                name: '',
                number: '',
                line_items: [],
                tax_items: []
            }
            $('#editPOInvoice').modal('hide');
        }


        $scope.viewInvoiceMobile = async id => {
            if (!id) {
                toastr.error('Press "Update" first.');
            }
            const { Filesystem, Browser } = window.Capacitor.Plugins;
            const pdfUrl = `${pqDomain}/maintenance-request/billing/invoice/${id}?token=${pqCachedToken}`; // Update with actual URL

            try {
                // Download the PDF file as a blob
                const response = await window.Capacitor.Plugins.CapacitorHttp.get({
                    url: pdfUrl,
                    responseType: 'blob'
                });

                const file = await Filesystem.writeFile({
                    path: `/Payquad/maintenance/po_invoice_${id}_${Date.now()}.pdf`,
                    data: response.data,
                    directory: 'DOCUMENTS',
                    recursive: true
                });

                await window.Capacitor.Plugins.FileOpener.open({
                    filePath: file.uri,
                    contentType: 'application/pdf'
                });

            } catch (error) {
                console.error('Unable to download or preview PDF:', error);
            }
        };

        $scope.chooseFileMobile = async () => {
            try {
                $scope.mobileFile = await window.Capacitor.Plugins.FilePicker.pickFiles({
                    limit: 1,
                    readData: true,
                });

                if ($scope.mobileFile && $scope.mobileFile.files.length > 0) {
                    $scope.selectedMobileFile = $scope.mobileFile.files[0].data;
                    $scope.selectedMobileFileName = $scope.mobileFile.files[0].name;
                } else {
                    console.error('No file selected.');
                }
            } catch (err) {
                console.error('Error while selecting or reading file:', err);
            }
        };


        $scope.viewPO = function (id) {
            window.open('/po-document-download/' + id, '_blank');
        }
        $scope.viewPOMobile = async function (id) {
            toastr.info('Downloading file...');
            const {Filesystem, CapacitorHttp, FileOpener} = window.Capacitor.Plugins;
            const pdfUrl = `${pqDomain}/po-document-download/${id}?token=${pqCachedToken}`;

            try {
                const response = await CapacitorHttp.get({
                    url: pdfUrl,
                    responseType: 'blob'
                });

                console.log(response.headers)
                const contentType = response.headers['content-type'] || response.headers['Content-Type'] || ''
                console.log(contentType)
                console.log(contentType.split('/'))

                const fileExtension = contentType.split('/').pop(); // Determine file extension from content type

                const file = await Filesystem.writeFile({
                    path: `Payquad/maintenance/po_invoice_${id}.${fileExtension}`,
                    data: response.data,
                    directory: 'DOCUMENTS',
                    recursive: true
                });

                await FileOpener.open({
                    filePath: file.uri,
                    contentType: contentType
                });

            } catch (error) {
                console.error('Unable to download or preview PDF:', error);
                toastr.error('Unable to download or preview PDF');
            }
        }
    }

}());
