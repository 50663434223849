(function () {
    'use strict';

    angular
        .module('app')
        .controller('LeaseApplicationManagerViewController', leaseApplicationManagerViewController);

    function leaseApplicationManagerViewController($scope, $http, $state, $rootScope, $timeout, $stateParams, $interval, resolvedApplication, langService) {
        const vm = this;
        $scope.t = langService.t('lease_app');

        $scope.active_tab = 'details';
        $scope.loading_spinner = 1;
        $scope.creditCheckDocument = null;
        $scope.form_submitting = 0;
        $scope.ref = {attachment_to_yardi: 0};

        $scope.isApp = false;

        $timeout(() => {
            $('#tableHistory').dataTable({
                retrieve: true,
                responsive: true,
            });
        });
        $timeout(() => {
            $scope.isApp = window.Capacitor.isNativePlatform();
        }, 1000);

        $scope.setActiveTab = function (tab) {
            $scope.active_tab = tab;
        };

        $scope.managers = [];
        $scope.chat = {
            message: '',
            visible_for_applicant: 1,
            managers: [],
            attachments: []
        };

        $scope.sendMessage = function () {
            if ($scope.total_file_size > 20) {
                toastr.error(t('file_size_excedes_limit'));
                return;
            }
            var dataAttachments = [];
            angular.forEach($scope.attachments, function (file, key) {
                dataAttachments.push({
                    name: file.name,
                    data: file.dataURL
                });
            });
            $scope.chat.attachments = dataAttachments;

            if ($scope.chat.message.length < 1) {
                toastr.error($scope.t('Please enter a message'));
            } else {
                var url = '/api/v1/lease-application/add-message/' + $scope.application.id;
                if ($rootScope.authUser.permissions != 'applicant' && $rootScope.authUser.permissions != 'tenant') {
                    url = '/api/v1/lease-application/manager-add-message/' + $scope.application.id;
                }   
                $scope.loading_spinner = 1;
                $http.post(url, $scope.chat).then(function success(data) {
                    $scope.application.messages.push(data.data.data.message);
                    $scope.chat.message = '';
                    $scope.loading_spinner = 0;

                    $scope.attachments = [];
                    $scope.chat.attachments = [];
                    $scope.myDropzone.removeAllFiles(true);
                }, function error(error){
                    $scope.loading_spinner = 0;
                    toastr.error($scope.t('Error sending message'));
                });
            }
        };

        $scope.checkForNewMessages = function () {
            var max_id = 0;

            for (var i in $scope.application.messages) {
                if ($scope.application.messages[i].id > max_id) {
                    max_id = $scope.application.messages[i].id;
                }
            }

            var url = '/api/v1/lease-application/messages/' + $scope.application.id + '/after/' + max_id;
            if ($rootScope.authUser.permissions != 'applicant') {
                var url = '/api/v1/lease-application/manager-messages/' + $scope.application.id + '/after/' + max_id;
            }
            $http.post(url).success(function (data) {
                for (var i in data.data) {
                    $scope.application.messages.push(data.data[i]);
                }
            });
        };

        $scope.downloadAttachment = async function (attachment) {
            var url = '/api/v1/lease-application/message-attachment-download/' + attachment.id;
            if (window.Capacitor.isNativePlatform()) {
                    // window.Capacitor.Plugins.Browser.open({url: (pqDomain + url + '?cordova='+(!!window.Capacitor.isNativePlatform() ? '1':'0') + '&token=' + pqCachedToken)});
                    try {
                        toastr.info('Downloading file...');
                        const {Filesystem, CapacitorHttp, FileOpener} = window.Capacitor.Plugins;
                        const pdfUrl = pqDomain + url + '?cordova=1&token=' + pqCachedToken;

                        try {
                            // Download the PDF file as a blob
                            const response = await CapacitorHttp.get({
                                url: pdfUrl,
                                responseType: 'blob'
                            });

                            console.log(response.headers)
                            const contentType = response.headers['content-type'] || response.headers['Content-Type'] || '';
                            console.log(contentType)
                            console.log(contentType.split('/'))

                            const fileExtension = contentType.split('/').pop(); // Determine file extension from content type

                            const file = await Filesystem.writeFile({
                                path: `Payquad/lease-applications/message_attachment${attachment.id}.${fileExtension}`,
                                data: response.data,
                                directory: 'DOCUMENTS',
                                recursive: true
                            });

                            await FileOpener.open({
                                filePath: file.uri,
                                contentType: contentType
                            });

                        } catch (error) {
                            console.error('Unable to download or preview PDF:', error);
                        }
                    } catch (e) {
                        alert(e)
                    }
            } else {
                window.open(url, '_blank');
            }
        };

        var promise = $interval($scope.checkForNewMessages, 10000);
        $scope.$on('$destroy', function () {
            if (promise) {
                $interval.cancel(promise);
            }
        });

        $scope.status_steps = {
            open: 0,
            review: 1,
            approved: 2,
            accepted: 3,
            signed: 4,
            complete: 5,
            converted: 6
        };
        $scope.status_step = 0;

        $scope.application = {
            id: $stateParams.id,
            user: {
                email: '',
                applicant: {}
            },
            applicants: [],
            employment_status: '',
            guarantors: [],
            emergency_contacts: [],
            documents: [],
            messages: [],
            attachments: [],
        };

        $scope.application = resolvedApplication.application;
        $scope.managers = resolvedApplication.message_managers;
        $scope.coapplicantsCreditChecks = {};
        $scope.guarantorsCreditChecks = {};
        //$scope.chat.managers = $scope.managers.map(item => item.id);
        $timeout(() => {
            $('#managers-select').multipleSelect({width: '240px', placeholder: 'Managers'});
        }, 0);

        if ($scope.application.property.lease_application_messages_managers_only == 1) {
            $scope.chat.visible_for_applicant = 0;
        }

        if (typeof $scope.status_steps[$scope.application.status] !== 'undefined') {
            $scope.status_step = $scope.status_steps[$scope.application.status];
        }
        $scope.loading_spinner = 0;

        for (let i = 0; i < resolvedApplication.application.applicants.length; i++) {
            if (resolvedApplication.application.applicants[i].user != null && resolvedApplication.application.applicants[i].user.personal != null && resolvedApplication.application.applicants[i].user.personal.certn_application_id) {
                $scope.coapplicantsCreditChecks[resolvedApplication.application.applicants[i].id] = {
                    name: resolvedApplication.application.applicants[i].first_name + ' ' + resolvedApplication.application.applicants[i].last_name
                };
            }
        }
        for (let i = 0; i < resolvedApplication.application.guarantors.length; i++) {
            if (resolvedApplication.application.guarantors[i].user != null && resolvedApplication.application.guarantors[i].user.personal != null && resolvedApplication.application.guarantors[i].user.personal.certn_application_id) {
                $scope.guarantorsCreditChecks[resolvedApplication.application.guarantors[i].id] = {
                    name: resolvedApplication.application.guarantors[i].first_name + ' ' + resolvedApplication.application.guarantors[i].last_name
                };
            }
        }


        $scope.note = {note: ''};
        $scope.addNote = function () {
            if ($scope.note.note == '') {
                return;
            }
            $scope.loading_spinner = 1;
            $http.post('/api/v1/lease-application/add-note/' + $scope.application.id, {note: $scope.note.note}).success(function (data) {
                $scope.loading_spinner = 0;
                if (!data.success) {
                    toastr.error(data.errors, $scope.t('Error adding note'));
                    return;
                }

                $scope.note.note = '';
                $scope.application.notes = data.data.notes;
            }).error(function (data) {
                $scope.loading_spinner = 0;
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        }

        $scope.offlineTCode = '';
        $scope.showCompletedOfflineForm = () => {
            if ($scope.application.property.lease_application_complete_offline_extension_id_required) {
                $('#offlineConvertTCodeModal').appendTo('body').modal('show');
            } else {
                $scope.completedOffline();
            }
        }

        $scope.completedOffline = () => {
            const url = $scope.application.property.lease_application_complete_offline_extension_id_required
                ? `/api/v1/lease-application/force-convert/${$scope.application.id}?tcode=${$scope.offlineTCode}`
                : `/api/v1/lease-application/force-convert/${$scope.application.id}`;


            $scope.loading_spinner = 1;
            $http.post(url).success(function (data) {
                $scope.loading_spinner = 0;
                if (!data.success) {
                    toastr.error(data.errors);
                    return;
                } else {
                    toastr.success($scope.t('Application converted successfully.'));
                    $state.go($state.current, {}, {reload: true});
                    $('#offlineConvertTCodeModal').modal('hide');
                }
            }).error(function (data) {
                $scope.loading_spinner = 0;
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                if (data.message) {
                    toastr.error(data.message);
                } else {
                    toastr.error('Failed to convert application');
                }
            });
        }

        $scope.changeStatusOffline = () => {
            const url = `/api/v1/lease-application/force-change-status/${$scope.application.id}`;

            $http.post(url).success(function (data) {
                if (!data.success) {
                    toastr.error(data.errors);
                } else {
                    toastr.success($scope.t('Status converted successfully.'));
                    $state.go($state.current, {}, {reload: true});
                    $('#offlineConvertTCodeModal').modal('hide');
                }
            }).error(function (data) {
                if (data.message) {
                    toastr.error(data.message);
                } else {
                    toastr.error('Failed to convert application status');
                }
            });
        }

        $scope.requestApproval = function () {
            let hasUnCreditCheckedCoApplicants = false;
            $scope.application.applicants.map(applicant => {
                if (!$scope.coapplicantsCreditChecks[applicant.id]) {
                    hasUnCreditCheckedCoApplicants = true;
                }
            });

            if ($scope.application.property.credit_check_enabled == 1
                && (!$scope.application.user.personal.certn_application_id > 0
                    || hasUnCreditCheckedCoApplicants)
                && $scope.application.property.lease_application_credit_check_mandatory_enabled
                && (!$scope.application.property.credit_check_document_upload_enabled
                   || !$scope.application.manual_credit_check_added)
            ) {
                toastr.error($scope.t('Please run a credit check before approving'));
                return;
            }

            if ($scope.application.property.credit_check_enabled == 0
                && $scope.application.property.lease_application_credit_check_mandatory_enabled
                && $scope.application.property.credit_check_document_upload_enabled == 1
                && !$scope.application.manual_credit_check_added
            ) {
                toastr.error($scope.t('Please run a credit check before approving'));
                return;
            }

            if (!confirm($scope.t('Are you sure you would like to request approval'))) {
                return;
            }
            $scope.loading_spinner = 1;
            $http.post('/api/v1/lease-application/request-approval/' + $scope.application.id).success(function (data) {
                $scope.loading_spinner = 0;
                if (!data.success) {
                    toastr.error(data.errors, $scope.t('Error requesting approval'));
                    return;
                } else {
                    toastr.success($scope.t('Application approval requested successfully'));
                    $state.go($state.current, {}, {reload: true});
                }
            }).error(function (data) {
                $scope.loading_spinner = 0;
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.approveApplication = function () {
            let hasUnCreditCheckedCoApplicants = false;
            $scope.application.applicants.map(applicant => {
                if (!$scope.coapplicantsCreditChecks[applicant.id]) {
                    hasUnCreditCheckedCoApplicants = true;
                }
            });

            if ($scope.application.property.credit_check_enabled == 1
                && (!$scope.application.user.personal.certn_application_id > 0
                    || hasUnCreditCheckedCoApplicants)
                && $scope.application.property.lease_application_credit_check_mandatory_enabled
                && (!$scope.application.property.credit_check_document_upload_enabled
                   || !$scope.application.manual_credit_check_added)
            ) {
                toastr.error($scope.t('Please run a credit check before approving this application'));
                return;
            }

            if ($scope.application.property.credit_check_enabled == 0
                && $scope.application.property.lease_application_credit_check_mandatory_enabled
                && $scope.application.property.credit_check_document_upload_enabled == 1
                && !$scope.application.manual_credit_check_added
            ) {
                toastr.error($scope.t('Please run a credit check before approving'));
                return;
            }

            if ($scope.form_submitting == 1) {
                return;
            }
            if (!confirm($scope.t('Are you sure you want to approve this application'))) {
                return
            }
            $scope.form_submitting = 1;
            $scope.loading_spinner = 1;
            $http.post('/api/v1/lease-application/approve/' + $scope.application.id).success(function (data) {
                $scope.form_submitting = 0;
                $scope.loading_spinner = 0;
                if (!data.success) {
                    toastr.error(data.errors, $scope.t('Error approving application'));
                    return;
                } else {
                    toastr.success($scope.t('Application was successfully approved'));
                    $state.go($state.current, {}, {reload: true});
                }
            }).error(function (data) {
                $scope.loading_spinner = 0;
                $scope.form_submitting = 0;
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        //reopenYardiApplication
        $scope.reopenYardiApplication = function () {
            if ($scope.form_submitting == 1) {
                return;
            }
            if (!confirm($scope.t('Are you sure you want to reopen this application') + '\n\n' + $scope.t('Reminder') + '\n' + $scope.t('The status will be changed to open and applicant may start the lease application process again'))) {
                return
            }
            $scope.form_submitting = 1;
            $scope.loading_spinner = 1;
            $http.post('/api/v1/lease-application/reopen-yardi/' + $scope.application.id).success(function (data) {
                $scope.form_submitting = 0;
                $scope.loading_spinner = 0;
                if (!data.success) {
                    toastr.error(data.errors, $scope.t('Error Reopening application'));
                    return;
                }

                toastr.success($scope.t('Application has been reopened'));
                $state.go('main.leaseApplicationList');
                return;
            }).error(function (data) {
                $scope.form_submitting = 0;
                $scope.loading_spinner = 0;
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.cancelApplication = function () {
            if ($scope.form_submitting == 1) {
                return;
            }
            if (!confirm($scope.t('Are you sure you want to cancel this application') + '\n\n' + $scope.t('Reminder') + '\n' + $scope.t('The status of the lease application will be cancelled and user will need to re-apply again for a lease application'))) {
                return
            }
            $scope.form_submitting = 1;
            $scope.loading_spinner = 1;
            $http.post('/api/v1/lease-application/cancel/' + $scope.application.id).success(function (data) {
                $scope.form_submitting = 0;
                $scope.loading_spinner = 0;
                if (!data.success) {
                    toastr.error(data.errors, $scope.t('Error cancelling application'));
                    return;
                }

                toastr.success($scope.t('Application has been cancelled'));
                $state.go('main.leaseApplicationList');
                return;
            }).error(function (data) {
                $scope.form_submitting = 0;
                $scope.loading_spinner = 0;
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.attach_credit_check = 0;
        $scope.uploadAttachments = function (application_id) {
            $scope.attach_credit_check = 0;
            $scope.showUploadAttachments(application_id);
        };

        $scope.uploadCreditCheckAttachments = function (application_id) {
            $scope.attach_credit_check = 1;
            $scope.showUploadAttachments(application_id);
        };

        $scope.showUploadAttachments = function (application_id) {
            $('#upload-attachment-modal').appendTo('body').modal('show');

            var uploadUrl = '/api/v1/lease-application/upload-attachments/' + application_id;
            if ($scope.attach_credit_check == 1) {
                uploadUrl = '/api/v1/lease-application/upload-credit-check/' + application_id;
            }
            var headers = {};

            if (!!window.Capacitor.isNativePlatform()) {
                uploadUrl = pqDomain + uploadUrl + '?cordova=' + (!!window.Capacitor.isNativePlatform()? '1' : '0') + '&token=' + pqCachedToken;
                headers = {
                    'Cache-Control': null,
                    'X-Requested-With': null,
                }
            }

            try {
                Dropzone.forElement("#upload-attachment-modal-dropzone").destroy();
            } catch (e) {
            }

            $('#upload-attachment-modal-dropzone').dropzone({
                uploadMultiple: false,
                parallelUploads: 1,
                maxFilesize: 20,
                addRemoveLinks: false,
                dictRemoveFile: $scope.t('remove'),
                dictFileTooBig: $scope.t('File is bigger than 20MB'),
                headers: headers,
                url: uploadUrl,

                init: function () {
                    console.log('init');
                    this.on("sending", function (file, xhr, formData) {
                        formData.append("send_to_yardi", $scope.ref.attachment_to_yardi);
                        //$scope.ref.attachment_to_yardi = 0;
                    });
                },

                error: function (file, response) {
                    var message = '';
                    if ($.type(response) === 'string') {
                        message = response; //dropzone sends it's own error messages in string
                    } else {
                        message = response.message;
                    }

                    toastr.error(message);

                    var _ref;
                    return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
                },
                success: function (file, response, done) {
                    var _ref;
                    if (!response.success) {
                        toastr.error(response.errors.message);
                        return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
                    }
                    //$scope.application.credit_check_file_url = response.data.urls[0];
                    $scope.application.attachments = response.data.attachments;

                    if ($scope.attach_credit_check == 1) {
                        $scope.application.manual_credit_check_added = 1;
                    }

                    toastr.success($scope.t('Attachment has been added'));
                    $scope.active_tab = 'details';

                    $('#upload-attachment-modal').modal('hide');
                    return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
                }
            });

        }

        $scope.chooseFileMobileCapacitor = async (type) => {
            $scope.mobileFile = await window.Capacitor.Plugins.FilePicker.pickFiles({
                limit: 1,
                readData: true,
            });
            var uploadUrl = '/api/v1/lease-application/upload-attachments/' + $scope.application.id;
            if ($scope.attach_credit_check == 1) {
                uploadUrl = '/api/v1/lease-application/upload-credit-check/' + $scope.application.id;
            }

            uploadUrl = pqDomain + uploadUrl + '?cordova=1&token=' + pqCachedToken;

            var data = new FormData();
            data.append('file', $scope.mobileFile.files[0].data);
            data.append('file_name',  $scope.mobileFile.files[0].name);
            data.append("send_to_yardi", $scope.ref.attachment_to_yardi);

            const headers = {
                'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA',
            };

            $http.post(uploadUrl, data, {transformRequest: angular.identity, headers: headers}).success(function (response) {
                if (!response.success) {
                    toastr.error(response.errors.message);
                }

                $scope.application.attachments = response.data.attachments;

                if ($scope.attach_credit_check == 1) {
                    $scope.application.manual_credit_check_added = 1;
                }

                toastr.success($scope.t('Attachment has been added'));
                $scope.active_tab = 'details';
                $('#upload-attachment-modal').modal('hide');
            }).error(function (data) {
                toastr.error(message);
            });
        }

        $scope.convertToHumanReadable = function (inputString) {
            // Split the input string into an array of parts
            const parts = inputString.split('.');

            // Map each part to its human-readable representation
            const humanReadableParts = parts.map((part, index) => {
                if (index === 0) {
                    // Capitalize the first letter of the first part
                    return part.charAt(0).toUpperCase() + part.slice(1);
                } else if (!isNaN(part)) {
                    // If the part is a number, skip it
                    return '';
                } else {
                    // Capitalize and add spaces between words
                    const words = part.split(/(?=[A-Z])/);
                    return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
                }
            });

            // Filter out empty parts and join them with " > "
            return humanReadableParts.filter(part => part !== '').join(' > ');
        }

        $scope.makeScreening = function () {
            if (!confirm($scope.t('Would you like to proceed to request a screening? (Fees apply per screening)'))) {
                return;
            }
            toastr.success($scope.t('Your screening results are being generated'));
            $http.get('/api/v1/certn-quick-report/' + $scope.application.id).success(function (data) {
                if (!data.success) {
                    toastr.error(data.message, $scope.t('Error getting screening'));

                    for (const [key, value] of Object.entries(data.errors)) {
                        toastr.error(value, $scope.convertToHumanReadable(key))
                    }
                    return;
                }

                $scope.application.user.personal.certn_application_id = data.data.id;
                toastr.success($scope.t('Screening results is ready You can download PDF in Options tab'));
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.makeCoApplicantScreening = function (applicantId, type) {
            if (!confirm($scope.t('Would you like to proceed to request a screening? (Fees apply per screening)'))) {
                return;
            }
            toastr.success($scope.t('Your screening results are being generated'));
            $scope.loading_spinner = 1;
            $http.get('/api/v1/coapplicants-certn-quick-report/' + applicantId).success(function (data) {
                $scope.loading_spinner = 0;
                if (!data.success) {
                    toastr.error(data.message, $scope.t('Error getting screening'));
                    return;
                }

                if (type === 'applicant') {
                    $scope.coapplicantsCreditChecks[data.data.id] = {
                        name: data.data.name
                    };
                }

                if (type === 'guarantor') {
                    $scope.guarantorsCreditChecks[data.data.id] = {
                        name: data.data.name
                    };
                }

                toastr.success($scope.t('Screening results is ready You can download PDF in Details tab'));
            }).error(function (data) {
                $scope.loading_spinner = 0;
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.deny = {reason: ''};

        $scope.doDenyApplication = function (send_notice) {
            if ($scope.form_submitting == 1) {
                return;
            }
            if (!confirm($scope.t('Are you sure you want to deny this application'))) {
                return
            }
            $scope.form_submitting = 1;
            $scope.loading_spinner = 1;
            $http.post('/api/v1/lease-application/deny/' + $scope.application.id + '/' + send_notice, $scope.deny).success(function (data) {
                $scope.form_submitting = 0;
                $scope.loading_spinner = 0;
                if (!data.success) {
                    toastr.error(data.errors, $scope.t('Error denying application'));
                    return;
                }

                toastr.success($scope.t('Application has been denied'));
                $state.go('main.leaseApplicationList');
                return;
            }).error(function (data) {
                $scope.form_submitting = 0;
                $scope.loading_spinner = 0;
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.denyApplication = function (send_notice) {
            console.log($scope.application.property.lease_application_decline_message);
            var title = $rootScope.trans('lease_app.deny_prompt');
            bootbox.prompt({
                title: title,
                value: $scope.application.property.lease_application_decline_message,
                callback: function (result) {
                    if (result == '') {
                        alert($scope.t('Please provide a reason for rejection'));
                        $scope.denyApplication(send_notice);
                        return;
                    }
                    if (result == null) {
                        return;
                    }

                    $scope.deny.reason = result;
                    $scope.doDenyApplication(send_notice);
                }
            });
        };

        $scope.markAsPaid = function () {
            $('#mark-paid-modal').appendTo('body').modal('show');
        };

        $scope.saveMarkAsPaid = function () {
            if ($scope.form_submitting == 1) {
                return;
            }
            if (!confirm($scope.t('Are you sure you want to mark this application as paid'))) {
                return
            }
            $scope.form_submitting = 1;
            $scope.loading_spinner = 1;
            $http.post('/api/v1/lease-application/mark-as-paid/' + $scope.application.id, {paid_note: $scope.application.paid_note}).success(function (data) {
                $scope.form_submitting = 0;
                $scope.loading_spinner = 0;
                if (!data.success) {
                    toastr.error(data.errors, $scope.t('Error marking application as paid'));
                    return;
                }

                if ($scope.application.paid == 1) {
                    toastr.success($scope.t('Application paid note has been updated'));
                } else {
                    toastr.success($scope.t('Application has been marked as paid'));
                }
                $scope.application.paid = 1;
                $scope.application.marked_as_paid_by_manager = 1;
                $('#mark-paid-modal').modal('hide');
            }).error(function (data) {
                $scope.form_submitting = 0;
                $scope.loading_spinner = 0;
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.markAsUnpaid = () => {
            if(!confirm($scope.t('confirm_clear_payment'))){ //Are you sure you want to clear the payment and mark this application as unpaid?
                return;
            }

            $http.post(`/api/v1/lease-application/${$scope.application.id}/mark-as-unpaid`).then((data)=>{
                toastr.success($scope.t('Application payment cleared'));
                $scope.application.paid = 0;
                $scope.application.marked_as_paid_by_manager = 0;
            }, (error)=>{
                toastr.error("Error clearing payment");
            });
        } 

        $scope.markLandlordReferenceComplete = (landlord)=>{
            landlord.reference_complete = 1;
            $http.post(`/api/v1/lease-application/${$scope.application.id}/landlord-reference-complete/${landlord.id}`).then((data)=>{
                toastr.success($scope.t('Landlord Reference Marked as Completed'));
            }, (error)=>{
                landlord.reference_complete = 0;
                toastr.error($scope.t("Error Marking Landlord Reference as Complete"));
            });
        }

        $scope.markLandlordReferenceIncomplete = (landlord)=>{
            landlord.reference_complete = 0;
            $http.post(`/api/v1/lease-application/${$scope.application.id}/landlord-reference-incomplete/${landlord.id}`).then((data)=>{
                toastr.success($scope.t('Landlord Reference Marked as Incomplete'));
            }, (error)=>{
                landlord.reference_complete = 1;
                toastr.error($scope.t("Error Marking Landlord Reference as Incomplete"));
            });
        }

        $scope.properties = [];
        $scope.getProperties = function () {
            $http.post('/api/v1/lease-application/get-properties-for-invite').success(function (data) {
                if (!data.success) {
                    toastr.error($scope.t('Error getting properties for invite'));
                    return;
                }

                $scope.properties = data.data.properties;
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };
        $scope.getProperties();

        $scope.change_property = {
            property_id: ''
        };
        $scope.openChangeProperty = function () {
            $scope.change_property.property_id = $scope.application.property_id;
            $('#change-property-modal').appendTo('body').modal('show');
        }

        $scope.saveChangeProperty = function () {
            if (!confirm($scope.t('Are you sure you want to change the property of this application') + '\n\n' + $scope.t('Reminder') + '\n' + $scope.t('Changing the property of the lease application won’t guarantee that the previous selected property will still be available in the future'))) {
                return
            }
            if ($scope.application.property_id == $scope.change_property.property_id) {
                toastr.error($scope.t('Please choose a new property'));
                return;
            }
            $('#change-property-modal').modal('hide');
            $('.change-property-modal').modal('hide');

            $scope.loading_spinner = 1;
            $http.post('/api/v1/lease-application/change-property/' + $scope.application.id, $scope.change_property).success(function (data) {
                $scope.loading_spinner = 0;
                if (!data.success) {
                    toastr.error(data.errors, $scope.t('Error changing property'));
                    return;
                }

                toastr.success($scope.t('Application property has been updated'));
                $state.go('main.leaseApplicationList');
            }).error(function (data) {
                $scope.loading_spinner = 0;
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        }

        $scope.reopen = {
            send_message: 'No',
            send_reason: 'No',
            reason: ''
        };
        $scope.openReopenModal = function () {
            $('#reopen-modal').appendTo('body').modal('show');
        }

        $scope.saveReopen = function () {
            if (!confirm($scope.t('Are you sure you want to reopen this application') + '\n\n' + $scope.t('Reminder') + '\n' + $scope.t('The status will be changed to open and applicant may start the lease application process again'))) {
                return
            }
            $('#reopen-modal').modal('hide');
            $scope.loading_spinner = 1;
            $http.post('/api/v1/lease-application/reopen/' + $scope.application.id, $scope.reopen).success(function (data) {
                $scope.loading_spinner = 0;
                if (!data.success) {
                    toastr.error(data.errors, $scope.t('Error reopening application'));
                    return;
                }

                toastr.success($scope.t('Application has been reopened'));
                $state.go('main.leaseApplicationList');
            }).error(function (data) {
                $scope.loading_spinner = 0;
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        }

        $scope.openSignOfflineModal = function () {
            $('#sign-offline-modal').appendTo('body').modal('show');
        };

        $scope.saveSignOffline = function () {
            if (!confirm($scope.t('Are you sure you want to upload signed lease?  This will update the application to completed status'))) {
                return;
            }

            if (document.getElementById('file1').files.length == 0) {
                toastr.error($scope.t("Please choose a file to upload"));
                return;
            }

            // if the file is over 20MB, have a confirmation dialog
            if ($scope.application.property.company.extension_type == 'yardi' && $scope.application.property.lease_applications_extension_enabled==1 && document.getElementById('file1').files[0].size > 20 * 1024 * 1024) {
                if (!confirm($scope.t('The file is over 20MB Are you sure you want to upload it'))) {
                    return;
                }
            }

            var data = new FormData();

            data.append('document', $scope.isApp ? $scope.selectedMobileFile : document.getElementById('file1').files[0]);


            $('#sign-offline-modal').modal('hide');

            const headers = window.Capacitor.isNativePlatform()
                ? {
                    'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA',
                }
                : {
                    'Content-Type': undefined,
                };

            $scope.loading_spinner = 1;
            $http.post('/api/v1/lease-application/upload-offline-lease/' + $scope.application.id, data, {transformRequest: angular.identity, headers: headers}).success(function (data) {
                $scope.loading_spinner = 0;
                if (!data.success) {
                    toastr.error($scope.t('Error uploading lease'));
                    return;
                }

                toastr.success($scope.t('Signed lease uploaded'));
                $state.go('main.leaseApplicationList');
            }).error(function (data) {
                $scope.loading_spinner = 0;
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.openSignOfflineApplicationModal = function () {
            $('#sign-offline-application-modal').appendTo('body').modal('show');
        };

        $scope.saveSignOfflineApplication = function () {
            if (!confirm($scope.t('Are you sure you want to upload signed application'))) {
                return;
            }

            if (!$scope.isApp && document.getElementById('file2').files.length == 0) {
                toastr.error($scope.t('Please choose a file to upload'));
                return;
            }

            var data = new FormData();
            data.append('document', $scope.isApp ? $scope.selectedMobileFile : document.getElementById('file2').files[0]);
            $('#sign-offline-application-modal').modal('hide');

            const headers = window.Capacitor.isNativePlatform()
                ? {
                    'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA',
                }
                : {
                    'Content-Type': undefined,
                };

            $scope.loading_spinner = 1;
            $http.post('/api/v1/lease-application/upload-offline-application/' + $scope.application.id, data, {transformRequest: angular.identity, headers: headers}).success(function (data) {
                $scope.loading_spinner = 0;
                if (!data.success) {
                    toastr.error($scope.t('Error uploading application'));
                    return;
                }

                toastr.success($scope.t('Signed application uploaded'));
                $state.go('main.leaseApplicationList');
            }).error(function (data) {
                $scope.loading_spinner = 0;
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.manualYardiCall = function (action) {
            if (!confirm('Are you sure?')) {
                return;
            }

            $http.post('/api/v1/lease-application/manual-yardi-call/' + $scope.application.id + '/' + action).success(function (data) {
                if (!data.success) {
                    toastr.error(data.message);
                    console.log(data.message);
                    return;
                }

                toastr.success('Call Successful');
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.addAttachmentMobile = async function () {
            $scope.mobileFile = await window.Capacitor.Plugins.FilePicker.pickFiles({
                limit: 1,
                readData: true,
            });

            $scope.attachments.push({
                name: $scope.mobileFile.files[0].name,
                dataURL: $scope.mobileFile.files[0].data,
            });
        }

        $scope.openDocument = async function (url) {
            // /lease-application-attachment
            // /lease-application-summary-pdf
            // /certn-quick-report-download/
            if (window.Capacitor.isNativePlatform()) {
                try {
                    // window.Capacitor.Plugins.Browser.open({url: (pqDomain + url + '?cordova=1&token=' + pqCachedToken)});
                    toastr.info('Downloading file...');
                    const {Filesystem, CapacitorHttp, FileOpener} = window.Capacitor.Plugins;
                    const pdfUrl = pqDomain + url + '?cordova=1&token=' + pqCachedToken;

                    try {
                        // Download the PDF file as a blob
                        const response = await CapacitorHttp.get({
                            url: pdfUrl,
                            responseType: 'blob'
                        });

                        console.log(response.headers)
                        const contentType = response.headers['content-type'] || response.headers['Content-Type'] || '';
                        console.log(contentType)
                        console.log(contentType.split('/'))

                        const fileExtension = contentType.split('/').pop(); // Determine file extension from content type

                        const file = await Filesystem.writeFile({
                            path: `Payquad/lease-applications/supporting_document_${Math.random().toString(16).slice(2)}.${fileExtension}`,
                            data: response.data,
                            directory: 'DOCUMENTS',
                            recursive: true
                        });

                        await FileOpener.open({
                            filePath: file.uri,
                            contentType: contentType
                        });

                    } catch (error) {
                        console.error('Unable to download or preview PDF:', error);
                    }
                } catch (e) {
                    alert(e)
                }
            } else if (url.startsWith('/certn-quick-report-download/')) {
                toastr.info('Your report is downloading. This may take a few moments.');

                $http.get(url)
                    .success(function (data) {
                        if (data.code==404) {
                            toastr.error(data.message || 'Error downloading credit check');
                            return;
                        }
                        window.open(url, '_blank');
                        toastr.success('Credit check downloaded');
                    })
                    .error(function (data) {
                        toastr.error(data.errors.message);
                    });
            }
            else {
                window.open(url, '_blank');
            }
        }

        $scope.deleteAttachment = function (id) {
            if (!confirm($scope.t("Are you sure you want to permanently delete this attachment?"))) {
                return;
            }

            var url = pqDomain + "/api/v1/lease-application-attachment/" + id + '?cordova=' + (!!window.Capacitor.isNativePlatform()? '1' : '0') + '&token=' + pqCachedToken;
            $scope.loading_spinner = 1;
            $http.delete(url).then(function success(data){
                $scope.loading_spinner = 0;
                for(var i = 0; i <  $scope.application.attachments.length; i++){
                    if($scope.application.attachments[i].id == data.data.id){
                        $scope.application.attachments.splice(i, 1);
                        break;
                    }
                }
                $scope.application.manual_credit_check_added = data.data.manual_credit_check_added;
                alert($scope.t("Document Deleted"));
            }, function error(data){
                $scope.loading_spinner = 0;
                alert($scope.t("Error deleting document"));
            });
        }

        $scope.required_documents = [];
        $scope.optional_documents = [];
        $scope.provided_documents = [];
        $scope.getDocumentTypes = function () {
            var url = '/api/v1/lease-application/manager-get-supporting-document-types-for-application/' + $scope.application.id;
            $http.post(url).success(function (data) {
                if (!data.success) {
                    return;
                }

                $scope.required_documents = data.data.required_types;
                $scope.optional_documents = data.data.optional_types;
                $scope.provided_documents = data.data.provided_documents;
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                $state.go('main.leaseApplicationCreateCompanies');
                toastr.error(data.error.message);
            });
        };
        $scope.getDocumentTypes();

        $scope.provided_lease_documents = [];
        $scope.supporting_lease_documents = [];
        $scope.getProvidedLeaseDocuments = function () {
            $http.post('/api/v1/lease-application/manager-get-provided-lease-documents-for-application/' + $scope.application.id).success(function (data) {
                if (!data.success) {
                    return;
                }

                $scope.provided_lease_documents = data.data.provided_lease_documents;
                $scope.supporting_lease_documents = data.data.supporting_lease_documents;
            }).error(function (data) {
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        }
        $scope.getProvidedLeaseDocuments();

        //message attatchments
        $scope.attachments = [];
        $scope.removeFile = function (file) {
            $scope.myDropzone.removeFile(file);
        };

        $scope.processDropzoneFiles = function () {
            $scope.total_file_size = 0;
            angular.forEach($scope.attachments, function (file, key) {
                var fileReader = new FileReader();
                var base64;
                fileReader.onload = function (fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    // base64 is the dataURL
                    file.dataURL = base64;
                };
                fileReader.readAsDataURL(file);

                $scope.total_file_size+= (file.size / 1000000);
            });
        };

        $scope.show_dropzone = 0;
        $scope.total_file_size = 0;
        $scope.initDropzone = function () {
            $scope.myDropzone = new Dropzone('#real-dropzone', {
                url: '/api/v1/noreturn/',
                autoProcessQueue: false,
                uploadMultiple: true,
                parallelUploads: 100,
                maxFiles: 20,
                maxFilesize: 8,
                addRemoveLinks: false,
                dictRemoveFile: 'Remove',
                dictFileTooBig: 'File is bigger than 8MB',

                // The setting up of the dropzone
                error: function (file, response) {
                    toastr.error(response);
                },
                addedfile: function (file, response) {
                    $timeout(function () {
                        $scope.attachments = $scope.myDropzone.getAcceptedFiles(); // eslint-disable-line angular/controller-as-vm
                        $scope.processDropzoneFiles();
                    }.bind(vm));
                },
                removedfile: function (file, response) {
                    $timeout(function () {
                        $scope.attachments = $scope.myDropzone.getAcceptedFiles(); // eslint-disable-line angular/controller-as-vm
                        $scope.processDropzoneFiles();
                    }.bind(vm));
                },
            });
        };

        $scope.clearApplicantCreditReport = function () {
            if (! confirm($scope.t("Are you sure you want to permanently delete this credit report?"))) {
                return;
            }

            $scope.loading_spinner = 1;
            $http.post('/api/v1/lease-application/clear-applicant-credit-report/' + $scope.application.id).success(function (data) {
                $scope.loading_spinner = 0;
                if (!data.success) {
                    toastr.error(data.errors, $scope.t('Error clearing credit report'));
                    return;
                }

                $scope.application.user.personal.certn_application_id = null;
                toastr.success($scope.t('Credit report cleared'));
            }).error(function (data) {
                $scope.loading_spinner = 0;
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.clearCoapplicantCreditReport = function(coapplicant_id) {
            if (! confirm($scope.t("Are you sure you want to permanently delete this credit report?"))) {
                return;
            }

            $scope.loading_spinner = 1;
            $http.post('/api/v1/lease-application/clear-coapplicant-credit-report/' + $scope.application.id + '/' + coapplicant_id).success(function (data) {
                $scope.loading_spinner = 0;
                if (!data.success) {
                    toastr.error(data.errors, $scope.t('Error clearing credit report'));
                    return;
                }

                delete $scope.coapplicantsCreditChecks[coapplicant_id];
                delete $scope.guarantorsCreditChecks[coapplicant_id];
                toastr.success($scope.t('Credit report cleared'));
            }).error(function (data) {
                $scope.loading_spinner = 0;
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };


        $timeout(function () {
            $scope.initDropzone();
        });

        $scope.resendInvite = function(application_id, applicant_id = false){
            if (!confirm('Are you sure you want to resend this Lease Application invite?')) {
                return;
            }

            $http.post('/api/v1/lease-application/resend-invite/' + application_id + '?applicant_id=' + applicant_id, {
                applicant_id: applicant_id
            }).success(function(data) {
                if (!data.success) {
                    toastr.error('Unable to resend invite');
                    return;
                }

                toastr.success('Invite Resent');
            }).error(function(data){
                if (typeof data.stopaction != 'undefined') {
                    return;
                }

                toastr.error(data.error.message);
            });
        };

        $scope.uploadAttachmentsMobile = async function (application_id) {
            const fileResult = await window.Capacitor.Plugins.FilePicker.pickFiles({
                limit: 1,
                readData: true,
            });

            toastr.success('Uploading Attachment');

            let uploadUrl = `/api/v1/lease-application/upload-attachments/${application_id}`;
            if ($scope.attach_credit_check == 1) {
                uploadUrl = `/api/v1/lease-application/upload-credit-check/${application_id}`;
            }

            if (fileResult.files.length > 0) {
                const file = fileResult.files[0];

                const formData = new FormData();
                formData.append('file', file.data);
                formData.append('file_name', file.name);
                formData.append('send_to_yardi', $scope.ref.attachment_to_yardi);

                const headers = window.Capacitor.isNativePlatform()
                    ? {
                        'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryuL67FWkv1CA',
                    }
                    : {
                        'Content-Type': undefined,
                    };

                const response = $http.post(pqDomain + uploadUrl, formData, {transformRequest: angular.identity, headers: headers}).success(function (response) {
                    if (response && response['success']) {
                        $scope.application.attachments = response.data.attachments;

                        if ($scope.attach_credit_check == 1) {
                            $scope.application.manual_credit_check_added = 1;
                        }

                        toastr.success($scope.t('Attachment has been added'));
                        $scope.active_tab = 'details';

                    } else {
                        toastr.error(response['errors'].message);
                    }
                });
            }
        }

        $scope.selectedMobileFile = null;

        $scope.chooseFileMobile = async () => {
            if (!$scope.isApp) {
                document.getElementById('file2').click();
                return
            }
            try {
                $scope.mobileFile = await window.Capacitor.Plugins.FilePicker.pickFiles({
                    limit: 1,
                    readData: true,
                });

                if ($scope.mobileFile && $scope.mobileFile.files.length > 0) {
                    $scope.selectedMobileFile = $scope.mobileFile.files[0].data;

                } else {
                    console.error('No file selected.');
                }
            } catch (err) {
                console.error('Error while selecting or reading file:', err);
            }
        };
    }
}());
