import {Injectable} from '@angular/core';
import {from, Observable, of} from 'rxjs';
import {ApiResponse} from '../../../shared/interfaces/api-response';
import {environment} from '../../../../environments/environment';
import {Property} from '../interfaces/property';
import {Portfolio} from '../interfaces/portfolio';
import { HttpClient } from '@angular/common/http';
import {PropertyChartData} from "../interfaces/propertyChartData";
import {Network} from "@capacitor/network";
import {Preferences} from "@capacitor/preferences";
import {switchMap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  constructor(
    private http: HttpClient
  ) {
  }


  public getPropertyList(): Observable<ApiResponse<Property[]>> {
    return from(Network.getStatus()).pipe(
      switchMap(status => {
          if (status.connected) {
            return this.http.get<ApiResponse<Property[]>>(`${environment.domain}/api/v1/properties`);
          } else {
            return from(this.getCachedProperties()).pipe(
              switchMap(cachedData => of(cachedData || null))
            );
          }
        }
      )
    );
  }


  public getPropertyNotes(id: number): Observable<ApiResponse<Property[]>> {
    return this.http.get<ApiResponse<Property[]>>(`${environment.domain}/api/v1/properties/${id}/notes`);
  }

  public savePropertyNotes(id: number, note: string): Observable<ApiResponse<Property[]>> {
    return this.http.post<ApiResponse<Property[]>>(`${environment.domain}/api/v1/properties/add-note/${id}`, {"note": note});
  }

  public getPortfolioList(): Observable<Portfolio[]> {
    return this.http.get<Portfolio[]>(`${environment.domain}/api/v1/portfolios`);
  }

  public getPropertyInfo(id: number): Observable<ApiResponse<Property>> {
    return this.http.get<ApiResponse<Property>>(`${environment.domain}/api/v1/properties/${id}/info`);
  }

  public getPropertyCharts(id: number): Observable<ApiResponse<PropertyChartData>> {
    return this.http.get<ApiResponse<PropertyChartData>>(`${environment.domain}/api/v1/properties/${id}/property-charts`);
  }

  // public getUnitList(property_id: number): Observable<ApiResponse<any>> {
  //   return this.http.post<ApiResponse<PropertyChartData>>(`${environment.domain}/api/v1/properties/get-property-units-for-manager-payments/${property_id}`, {});
  // }

  public getUnitList(property_id: number): Observable<ApiResponse<Property[]>> {
    return from(Network.getStatus()).pipe(
      switchMap(status => {
          if (status.connected) {
            return this.http.post<ApiResponse<Property[]>>(`${environment.domain}/api/v1/properties/get-property-units-for-manager-payments/${property_id}`, {});
          } else {
            return from(this.getCachedUnit(property_id)).pipe(
              switchMap(cachedData => of(cachedData || null))
            );
          }
        }
      )
    );
  }

  /**
   * OFFLINE
   */

  cacheProperties(propertyList: any, propertyUnits: any) {
    Preferences.set({
      key: 'cachedPropertyList',
      value: JSON.stringify({data: propertyList})
    });

    propertyList.forEach((property: any) => {
      console.log('caching property', property);
      Preferences.set({
        key: `cachedPropertyUnits-${property.id}`,
        value: JSON.stringify(propertyUnits[property.id])
      });
    })
  }

  async getCachedProperties(): Promise<ApiResponse<Property[]> | null> {
    const { value } = await Preferences.get({ key: 'cachedPropertyList' });
    return value ? JSON.parse(value) : null;
  }
  async getCachedUnit(property_id: number): Promise<ApiResponse<Property[]> | null> {
    const { value } = await Preferences.get({ key: 'cachedPropertyUnits-' + property_id });
    return value ? JSON.parse(value) : null;
  }
}
