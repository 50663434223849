import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Network} from "@capacitor/network";

export const OfflineGuard = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Promise<Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree> => {

  const status = await Network.getStatus();

  if (!state.url.includes('dashboard') && !status.connected) {
    // @ts-ignore
    window.toastr.warning('You are offline, please connect to the internet to access this page', null, {
      positionClass: 'toast-center-center'
    });
    window.location.href = '/#/offline';
    return of(false);
  }

  return status.connected;
};
