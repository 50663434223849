import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { State } from '../../store/app.state';
import { User, UserPermissions } from '../interfaces/user';
import { selectAppUser } from '../../store/app.selectors';
import { catchError, exhaustMap, filter, map, take, tap } from 'rxjs/operators';
import { loadUser } from '../../store/app.actions';
import { APP_USER_STATUS } from '../constants'
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

export const PermissionGuard = (requiredPermission: UserPermissions): CanActivateFn => {
    return (
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
        console.log('requiredPermission', requiredPermission);

        const store: Store<State> = inject(Store<State>);
        const toastr: ToastrService = inject(ToastrService);
        const t: TranslateService = inject(TranslateService);

        const getUserFromStateOrAPI = (): Observable<User | null> => {
            return store.pipe(
                select(selectAppUser),
                tap((user: User | null | APP_USER_STATUS.NOT_AUTHORIZED) => {
                    if (user === null) {
                        store.dispatch(loadUser());
                    }
                }),
                filter((user: User | null | APP_USER_STATUS.NOT_AUTHORIZED) => {
                    return user !== null;
                }),
                map((user) => user === APP_USER_STATUS.NOT_AUTHORIZED ? null : user as User),
                take(1)
            );
        }

        return getUserFromStateOrAPI().pipe(
            exhaustMap((u) => {
                if (u.user_permissions[requiredPermission]) {
                    return of(true);
                } else {
                    toastr.error(t.instant('You do not have permission to access this page'));
                    window.location.href = '/#/dashboard';
                    return of(false);
                }
            }),
            catchError(() => of(false)),
        )
    }
}

